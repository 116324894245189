import React, { useEffect } from 'react'
import './App.css'
import Routes from './routes'
import { BrowserRouter as Router } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import { I18nextProvider } from 'react-i18next'
import { setIPAddress } from './product/common/api'
import AuthState from './product/context/auth/authState'
import CustomCommonState from './custom/context/common/commonState'
import ProductCommonState from './product/context/common/commonState'
import UserState from './product/context/user/userState'
import AlertState from './product/context/alert/alertState'
import ProductState from './product/context/product/productState'
import InvoiceState from './product/context/invoice/invoiceState'
import AuctionState from './product/context/auction/auctionState'
import CreditState from './product/context/credit/creditState'
import OfflineState from './product/context/offline/offlineState'
import CartState from './product/context/cart/cartState'
import BuyerState from './product/context/buyer/buyerState'
import AutopltState from './product/context/autopilot/autopltState'
import PayState from './product/context/payment/payState'
import CardState from './product/context/stripe/card/cardState'
import CustomerState from './product/context/stripe/customer/customerState'
import BankState from './product/context/stripe/bank/bankState'
import { NavLink, useLocation, useHistory } from 'react-router-dom'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { SnackbarProvider } from 'notistack'
import publicIp from 'public-ip'
import Alerts from './product/common/alert'
import HeadAlert from './custom/components/molecules/HeadAlert'
import BuynowState from './product/context/buynow/buynowState'
import AppointmentState from './product/context/appointment/appointmentState'
import NorthCapitalState from './product/context/payment/northcapital/northcapitalState'
import PlaidState from './product/context/payment/plaid/plaidState'
import i18n from './product/common/language-i18n'
import withRoot from './withRoot'
import KlaviyoState from './custom/context/klaviyo/klaviyoState'
import RoyaltyState from './custom/context/royalty/royaltyState'
import ZohoState from './custom/context/zoho/zohoState'
import SettingState from './product/context/seller/setting/settingState'
import TipaltiState from './custom/context/tipalti/tipaltiState'
import { handleRedirectInternal } from './product/common/components'

global.site_url = process.env.REACT_APP_DOMAIN
global.images_url = global.site_url + '/uploads/product/'
global.ignoreStates = []
global.ignoreCountry = []
global.defaultCountry = 'US'
global.registerToken = true
global.klaviyoPublicKey = `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${process.env.REACT_APP_KLAVIYO_PUBLIC_KEY}`

const App = () => {
    const history = useHistory()
    useEffect(() => {
        async function fetchData() {
            try {
                const ipaddress = await publicIp.v4()
                if (ipaddress) {
                    setIPAddress(ipaddress)
                    localStorage.setItem('ipaddress', ipaddress)
                }
            } catch (err) {
                console.log('try', err)
            }
        }
        fetchData()
        if (window.location.search) {
            let params = new URLSearchParams(window.location.search)
            if (params.get('utm_source'))
                localStorage.setItem('utm_source', params.get('utm_source'))
            if (params.get('utm_medium'))
                localStorage.setItem('utm_medium', params.get('utm_medium'))
            if (params.get('utm_campaign'))
                localStorage.setItem('utm_campaign', params.get('utm_campaign'))
        }
        // window.createKlaviyoScript(global.klaviyoPublicKey)
    }, [])

    let kycCheck = new URLSearchParams(window.location.search).get('kycAmlCheck')
    console.log(kycCheck, 'kycCheck', history)

    useEffect(() => {
        if (kycCheck) {
            localStorage.setItem('kycCheck', kycCheck)
            if (!localStorage.token) {
                window.location.href = '/login'
            }
        }
    }, [kycCheck, localStorage.token])

    return (
        <CookiesProvider>
            <I18nextProvider i18n={i18n}>
                <CustomCommonState>
                    <ProductCommonState>
                        <AuthState>
                            <AlertState>
                                <UserState>
                                    <CustomerState>
                                        <CardState>
                                            <BankState>
                                                <ProductState>
                                                    <AuctionState>
                                                        <BuynowState>
                                                            <CartState>
                                                                <AppointmentState>
                                                                    <CreditState>
                                                                        <PayState>
                                                                            <OfflineState>
                                                                                <InvoiceState>
                                                                                    <BuyerState>
                                                                                        <AutopltState>
                                                                                            <SettingState>
                                                                                                <NorthCapitalState>
                                                                                                    <KlaviyoState>
                                                                                                        <PlaidState>
                                                                                                            <RoyaltyState>
                                                                                                                <TipaltiState>
                                                                                                                    <ZohoState>
                                                                                                                        <MuiPickersUtilsProvider
                                                                                                                            utils={
                                                                                                                                MomentUtils
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <SnackbarProvider
                                                                                                                                maxSnack={
                                                                                                                                    3
                                                                                                                                }
                                                                                                                            >
                                                                                                                                <div className="App">
                                                                                                                                    <Alerts />
                                                                                                                                    <HeadAlert />
                                                                                                                                    <Router>
                                                                                                                                        <Routes />
                                                                                                                                    </Router>
                                                                                                                                </div>
                                                                                                                            </SnackbarProvider>
                                                                                                                        </MuiPickersUtilsProvider>
                                                                                                                    </ZohoState>
                                                                                                                </TipaltiState>
                                                                                                            </RoyaltyState>
                                                                                                        </PlaidState>
                                                                                                    </KlaviyoState>
                                                                                                </NorthCapitalState>
                                                                                            </SettingState>
                                                                                        </AutopltState>
                                                                                    </BuyerState>
                                                                                </InvoiceState>
                                                                            </OfflineState>
                                                                        </PayState>
                                                                    </CreditState>
                                                                </AppointmentState>
                                                            </CartState>
                                                        </BuynowState>
                                                    </AuctionState>
                                                </ProductState>
                                            </BankState>
                                        </CardState>
                                    </CustomerState>
                                </UserState>
                            </AlertState>
                        </AuthState>
                    </ProductCommonState>
                </CustomCommonState>
            </I18nextProvider>
        </CookiesProvider>
    )
}

export default withRoot(App)
