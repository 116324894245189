import React, { useState, useContext, useEffect, useRef } from 'react'
import 'react-image-gallery/styles/css/image-gallery.css'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import './ProductView.css'
import Buynow from '../../molecules/Buynow/BuynowItem'
import CustomCommonContext from '../../../../custom/context/common/commonContext'
import ProductCommonContext from '../../../../product/context/common/commonContext'
import {
    dateFormatFront,
    currencyFormat,
    currencyFormatTwo,
    numberFormatCommaSeparate,
    currencyFormatTwoNoDollar,
    dateTimeFormatFunction,
    currencyFormatInt,
    numberFormatPositive,
} from '../../../common/components'
import Timer from '../../../../custom/common/timer'
import Bidding from '../../molecules/Bidding/BiddingItem'
import TTWBidding from '../../molecules/Bidding/TTWBidding'
import BuyerContext from '../../../../product/context/buyer/buyerContext'
import ProductContext from '../../../../product/context/product/productContext'
import AuthContext from '../../../../product/context/auth/authContext'
import Loaders from '../../molecules/Loaders'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import Popup from '../Popup'
import { messageHandler, messageHandlerSingle } from '../../../../product/common/socketHandler'
import { ttwMessageHandler } from '../../../../product/common/ttwSocketHandler'
import AlertContext from '../../../../product/context/alert/alertContext'
import openSocket from 'socket.io-client'
import CustomPopover from '../../atoms/Popover'
import { Tooltip } from '@material-ui/core'
import { object } from 'yup'
import TrackCard from '../../molecules/TrackCard'
import ProductMoreinfo from '../ProductMoreinfo'
import FavoriteCheckbox from '../../../../product/components/atoms/FavoriteCheckbox'
import AppLayout from '../../templates/AppLayout'
import HelpIcon from '@material-ui/icons/Help'
import RadialChart from './RadialChart'
import { handleRedirectInternal, mapData } from '../../../../product/common/components'
import { useHistory, Link } from 'react-router-dom'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import { useMediaQuery } from '@material-ui/core'
import { google, outlook, office365, yahoo, ics } from 'calendar-link'
import NorthCapitalContext from '../../../../product/context/payment/northcapital/northcapitalContext'
import KlaviyoContext from '../../../../custom/context/klaviyo/klaviyoContext'
import { Helmet } from 'react-helmet-async'
import MetaTags from '../../molecules/MetaTags'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Button, IconButton } from '@material-ui/core'
import ReactPlayer from 'react-player'
import { useLocation } from 'react-router-dom'
import BuynowFree from '../../molecules/Buynow/BuynowFree'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import royaltyContext from '../../../../custom/context/royalty/royaltyContext'
import InvoiceContext from '../../../../product/context/invoice/invoiceContext'
import ReadMore from '../../../../product/components/atoms/ReadMore'
import BidHistory from '../BidHistory'
import { Line } from 'react-chartjs-2'
import SecondaryButton from '../../../../product/components/atoms/SecondaryButton'
import CustomDialog from '../../organisms/Dialog'
import TertiaryButton from '../../atoms/TertiaryButton'

// const Accordion = withStyles({
//     root: {
//         border: '1px solid rgba(0, 0, 0, .125)',
//         boxShadow: 'none',
//         '&:not(:last-child)': {
//             borderBottom: 0,
//         },
//         '&:before': {
//             display: 'none',
//         },
//         '&$expanded': {
//             margin: 'auto',
//         },
//     },
//     expanded: {},
// })(MuiAccordion)

// const AccordionSummary = withStyles({
//     root: {
//         backgroundColor: '#fff',
//         marginBottom: -1,
//         minHeight: 40,
//         '&$expanded': {
//             minHeight: 40,
//         },
//     },
//     content: {
//         '&$expanded': {
//             margin: '0',
//         },
//     },
//     expanded: {},
// })(MuiAccordionSummary)

// const AccordionDetails = withStyles((theme) => ({
//     root: {
//         padding: '10px',
//     },
// }))(MuiAccordionDetails)
// function accorianTerms() {
//     return [
//         {
//             title: 'Terms and conditions',
//             details: 'Terms and condition details',
//         },
//     ]
// }

export const ViewDescription = (data) => {
    return data && data?.length !== 0 ? (
        <div className={`pvAbt`}>
            {data && data?.length !== 0 ? (
                <>
                    {(data && data?.length < 150) || showDescription ? (
                        <>
                            <div
                                className="pdtAbtCntnr"
                                dangerouslySetInnerHTML={{
                                    __html: data,
                                }}
                            ></div>
                            {data?.length > 150 && (
                                <p
                                    className="showDscrption"
                                    onClick={() => setShowDescription(false)}
                                >
                                    Hide full description
                                </p>
                            )}
                        </>
                    ) : (
                        <>
                            <div
                                className="pdtAbtCntnr"
                                dangerouslySetInnerHTML={{
                                    __html: data && data.slice(0, 150) + '....',
                                }}
                            ></div>
                            <p className="showDscrption" onClick={() => setShowDescription(true)}>
                                See full description
                            </p>
                        </>
                    )}
                </>
            ) : (
                <small>No Data Found</small>
            )}
        </div>
    ) : null
}

const ProductView = (props) => {
    // const accordianData = accorianTerms()
    const [expanded, setExpanded] = useState()
    const [images, setImages] = useState([])
    let [viewProduct, setViewProduct] = useState([])
    let [myBids, setMyBids] = useState([])
    let [totalShares, setTotalShares] = useState(0)
    let [bidHistoryChange, setBidHistoryChange] = useState(null)
    const [royaltyChartData, setRoyaltyChartData] = useState([])
    const [tableFour, setTableFour] = useState(true)
    const [royaltyChartLabel, setRoyaltyChartLabel] = useState([])
    const [certificateData, setCertificateData] = useState({})
    const [increasePopup, setIncreasePopup] = useState(false)
    const [increaseVal, setIncreaseVal] = useState({})

    let [allTotalShares, setAllTotalShares] = useState(0)
    const [kycAmlChecked, setKycAmlChecked] = useState(false)
    let [totalBidPrice, setTotalBidPrice] = useState(0)
    const [product, setProduct] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const alertContext = useContext(AlertContext)
    const authContext = useContext(AuthContext)
    const invoiceContext = useContext(InvoiceContext)
    const productContext = useContext(ProductContext)
    const customCommonContext = useContext(CustomCommonContext)
    const productCommonContext = useContext(ProductCommonContext)
    const { updateProfile } = useContext(KlaviyoContext)
    let { getFutureProxyList, responseStatus: responseStatusTtw, ttwBidConfirm } = useContext(
        BuyerContext,
    )
    const { search_allusertransactions, getAllUserTransactions } = invoiceContext

    const [viewMoreIfo, setMoreInfo] = useState(false)
    const [trackIndex, setTrackIndex] = useState(0)
    const vwPortSize = useMediaQuery('(max-width:1400px)')
    const vwSmDevice = useMediaQuery('(max-width:800px)')
    const myRef = useRef(null)
    const [howtoBuy, setHowToBuy] = useState(false)
    const [showPrivateSale, setShowPrivateSale] = useState(false)

    const { setAlert } = alertContext
    const { user, isAuthenticated } = authContext
    const {
        search_allproducts,
        search_allsimilar,
        getAllSimilarProducts,
        getAllSearchProducts,
        getAllTtwBidHistoryByUser,
        search_alldashboardproducts,
        getAllDashboardProducts,
        responseStatus,
        ttwBidsByUser,
    } = productContext
    const {
        north_capital_bank_details,
        northCapitalUser,
        getExternalAccount,
        north_capital_card_details,
        addCampaignContact,
        getCreditCard,
        getLinkedCreditCard,
        responseStatus: responseStatusCampaign,
    } = useContext(NorthCapitalContext)
    const {
        allCategory,
        allCondition,
        allLocations,
        allIncomeType,
        allPaidBy,
        allAssetType,
        allTerm,
        allConfigurationVariables,
        notifyBuyAllNowShareAction,
        checkBuyAllNowShareRequestSent,
        clearResponse: clearBuyAllSharesResponse,
        responseStatus: notifyBuyAllSharesResponse,
        responseStatus: checkBuyAllNowShareRequestSentResponse,
    } = customCommonContext
    const {
        getRoyalties,
        listOwnedSongShares,
        getDashboardData,
        listOpenSongShares,
        listSongShares,
        getRoyaltyChartData,
        getInvestementChartData,
        royalties_list,
        dashboardData_List,
        ownedSongShares_List,
        openSongShares_List,
        royalty_chart_data,
        songshares_list,
        investment_chart_data,
    } = useContext(royaltyContext)
    const [songshareTitle, setSongshareTitle] = useState('Total')
    const [bank, setBank] = useState(false)
    const [recentTransactions, setRecentTransactions] = useState([])
    const [card, setCard] = useState(false)
    const { setBidHistoryValue, setTTWBidHistoryValue, configVariables } = productCommonContext
    const [mkProxy, setMkProxy] = useState(false)
    const [normalProxy, setNormalProxy] = useState(false)
    const [pxBidStatus, setPxBidStatus] = useState(false)
    const [key, setKey] = useState()
    const [futureProxy, setFutureProxy] = useState({ users: [], bids: {} })
    const [dummyWidth, setDummyWidth] = useState('')
    const history = useHistory()
    const [event, setEvent] = useState(null)
    const [toggleAutoDialog, setToggleAutoDialog] = useState(false)
    const [trigger, setTrigger] = useState(true)
    const [showDescription, setShowDescription] = useState(false)

    const queryParam = useLocation().search
    const private_sale = new URLSearchParams(queryParam).get('private_sale')
    let currentDate = new Date()
    let currentQuarter = Math.floor(currentDate.getMonth() / 3)

    const [search, setSearch] = useState({
        limit: 120,
        page: 1,
        orderby: 'p.date_closed, desc',
        market_status: ['open', 'closed', 'sold', 'unsold', 'relisted'],
        order: '',
        freeShare: 1,
        filters: {
            searchbar: {
                value: '',
                type: 'like',
                field: 'it.leadDescription',
            },
            id: {
                value: '',
                type: 'in',
                field: 'p.id',
            },
            project: {
                value: '',
                type: 'in',
                field: 'cp.artist_id',
            },
        },
        privateSale: private_sale && typeof private_sale == 'string' ? parseInt(private_sale) : 0,
    })

    const formik = useFormik({
        initialValues: {
            wsprice: '',
            // id: props.data.id,
            bidding_type: '',
        },
        // validationSchema: validationArray,
        onSubmit: (values) => {
            if (user.mail_verified) {
                if (product.deposit && !product.bid_deposit_id) {
                    setToggleDialogDeposit(true)
                } else if (toggleDialog) {
                    bidConfirm(values)
                    setToggleDialog(false)
                    formik.resetForm()
                } else {
                    setToggleDialog(true)
                }
            } else {
                handleRedirectInternal(history, 'account/profile')
                setAlert('Please verify your email', 'error')
            }
        },
    })

    const formikAuto = useFormik({
        initialValues: {
            proposed_amount: '',
            id: 0,
            bidding_type: 'hard',
            proposed_qty: '',
            customBidData: {
                autopurchase: '',
            },
            autopurchase: '',
        },
        // validationSchema: validationAutoArray,
        onSubmit: (values) => {
            console.log(values, 'coming ttw values')

            values.customBidData = {
                autopurchase: values.autopurchase ? 1 : 0,
            }
            ttwBidConfirm(values)
            setToggleAutoDialog(false)
        },
    })

    const formikRoyalty = useFormik({
        initialValues: {
            fromDate: '',
            toDate: '',
            song_share: parseInt(props.match.params.id),
            royalty_quarter_dropdown: 'life_time',
            lastQuarterStartDate: '',
            lastQuarterEndDate: '',
            chartStartDate: '',
            chartEndDate: '',
            investment_quarter_dropdown: 'life_time',
            page_opensong: 1,
            page_ownedsong: 1,
            order_opensong: 'desc',
            orderby_opensong: 'id',
        },
        onSubmit: (values) => {},
    })

    const liveBidAmount = [
        {
            name: 'wsprice',
            type: 'number',
            placeholder: `${'Min bid'} ${
                viewProduct[0] && viewProduct[0].next_bid
                    ? currencyFormatInt(viewProduct[0].next_bid)
                    : currencyFormatInt(0)
            }`,
            class: '',
            size: 'small',
            autoFocus: false,
            formik: formik,
        },
    ]

    const bidAmount = [
        {
            // label:'Enter bid amount',
            name: 'wsprice',
            type: 'number',
            placeholder: 'Enter unit qty',
            class: '',
            size: 'small',
            autoFocus: false,
            formik: formik,
        },
    ]

    const [productLoaded, setProductLoaded] = useState(false)

    useEffect(() => {
        setProduct(props.data)
    }, [props.data])
    useEffect(() => {
        if (search_alldashboardproducts.records.length) {
            let all_data = search_alldashboardproducts.records
            setCertificateData(all_data)
        } else {
            setCertificateData([])
        }
    }, [search_alldashboardproducts])
    useEffect(() => {
        // getCreditCard()
        getLinkedCreditCard()
        getExternalAccount()
    }, [])

    const changeAutoDialogStatus = () => {
        setToggleAutoDialog(!toggleAutoDialog)
    }

    useEffect(() => {
        if (localStorage.getItem('paymentInfo') && northCapitalUser) {
            let infoVal = JSON.parse(localStorage.getItem('paymentInfo'))
            console.log(infoVal, northCapitalUser, user, 'infoVal')
            formikAuto.setValues(infoVal)

            // const individualInternationalUser =
            //     user.user_type == 0 && user.domesticYN == 'international_account' ? true : false
            // if (
            //     ((user.user_type === 1 || (user.user_type === 0 && individualInternationalUser)) &&
            //         (northCapitalUser?.amlstatus === 'Approved' ||
            //             northCapitalUser?.amlstatus === 'Auto Approved' ||
            //             northCapitalUser?.amlstatus === 'Manually Approved')) ||
            //     (user.user_type === 0 &&
            //         !individualInternationalUser &&
            //         (northCapitalUser?.amlstatus === 'Approved' ||
            //             northCapitalUser?.amlstatus === 'Auto Approved' ||
            //             northCapitalUser?.amlstatus === 'Manually Approved') &&
            //         (northCapitalUser?.kycstatus === 'Approved' ||
            //             northCapitalUser?.kycstatus === 'Auto Approved' ||
            //             northCapitalUser?.kycstatus === 'Manually Approved'))
            // ) {
            //     formikAuto.setFieldValue(`customBidData.autopurchase`, 1)
            //     formikAuto.setFieldValue(`autopurchase`, 1)
            //     // formikAuto.values.customBidData.autopurchase = 0
            //     setKycAmlChecked(true)
            // } else {
            //     formikAuto.setFieldValue(`customBidData.autopurchase`, 0)
            //     formikAuto.setFieldValue(`autopurchase`, 0)
            //     setKycAmlChecked(false)
            // }
            setToggleAutoDialog(true)
            // ttwBidConfirm(infoVal)
            localStorage.removeItem('paymentInfo')
        }
    }, [localStorage.getItem('paymentInfo'), northCapitalUser])

    useEffect(() => {
        if (localStorage.getItem('bidIncrease')) {
            let infoVal = JSON.parse(localStorage.getItem('bidIncrease'))
            setIncreasePopup(true)
            setIncreaseVal(infoVal)
            console.log(infoVal, 'infoVal')
            localStorage.removeItem('bidIncrease')
        }
    }, [localStorage.getItem('bidIncrease')])

    const changeDialogStatus = () => {
        setIncreasePopup(!increasePopup)
    }

    const handleIncreaseFunc = () => {
        ttwBidConfirm(increaseVal)
        changeDialogStatus()
        localStorage.removeItem('current_price')
        localStorage.removeItem('old_price')
        setIncreaseVal({})
    }

    const cancelFunc = () => {
        setIncreaseVal({})
        localStorage.removeItem('current_price')
        localStorage.removeItem('old_price')
        setIncreasePopup(false)
    }

    useEffect(() => {
        if (user && user.id) {
            getAllDashboardProducts({
                limit: 10,
                page: 1,
                orderby: 'p.date_closed,asc',
                order: '',
                filters: {
                    id: {
                        value: parseInt(props.match.params.id),
                        type: 'in',
                        field: 'p.id',
                    },
                    auctiontype: {
                        value: 'buynow',
                        type: 'in',
                        field: 'p.auctiontype',
                    },

                    buynow_ownership_certificate: {
                        type: 'notnull',
                        value: '',
                        field: 'it.upload_certificate_images',
                    },
                },
                action: 'buynow_paid',
            })
            // getAllUserTransactions({
            //     limit: 5,
            //     page: 1,
            //     orderby: 1,
            //     includeRoyalty: 1,
            //     filters: {
            //         project_id: {
            //             type: 'in',
            //             value: parseInt(props.match.params.id),
            //             field: 'all_transactions.buynow_project_id',
            //         },
            //     },
            // })
        }
    }, [user])
    useEffect(() => {
        if (
            north_capital_bank_details &&
            north_capital_bank_details.data &&
            north_capital_bank_details.data.statusDesc &&
            north_capital_bank_details.data.statusDesc.AccountNumber
        ) {
            setBank(true)
        }
        if (
            north_capital_card_details &&
            north_capital_card_details.data &&
            north_capital_card_details.data.creditcardDetails &&
            north_capital_card_details.data.creditcardDetails.creditCardNumber
        ) {
            setCard(true)
        }
    }, [north_capital_bank_details, north_capital_card_details])

    const getFutureProxy = () => {
        if (viewProduct[key]) {
            let payload = {
                currentbid_increment: viewProduct[key].currentbid_increment,
                id: viewProduct[key].id,
                bid_position: viewProduct[key].ttwBids.length
                    ? viewProduct[key].ttwBids[0].bid_position
                    : 1,
                size: 1,
                reverse: true,
            }
            getFutureProxyList(payload)
        }
        setPxBidStatus(true)
    }

    useEffect(() => {
        if (responseStatusTtw) {
            if (responseStatusTtw.from === 'future_proxy') {
                if (responseStatusTtw.status === 'success') {
                    setFutureProxy(responseStatusTtw.data)
                }
            }
        }
    }, [responseStatusTtw])

    useEffect(() => {
        setTotalBidPrice(0)
        setTotalShares(0)
        setMyBids([])
        if (ttwBidsByUser && ttwBidsByUser.records.length) {
            setMyBids(ttwBidsByUser.records)
            let totalPrice = 0
            let totalShare = 0
            ttwBidsByUser.records.forEach((bid) => {
                totalShare += bid.proposed_amount
                totalPrice += bid.currentbid_increment * bid.proposed_amount
            })
            setTotalBidPrice(totalPrice)
            setTotalShares(totalShare)
        }
    }, [ttwBidsByUser])

    useEffect(() => {
        if (search.filters.id.value) {
            setIsLoading(true)
            setProductLoaded(true)
            getAllSearchProducts(search)
        }
    }, [search])

    useEffect(() => {
        setSearch({
            ...search,
            filters: {
                ...search.filters,
                id: {
                    ...search.filters.id,
                    value: props.match.params.id,
                },
            },
        })
    }, [])

    useEffect(() => {
        /* setRecentTransactions(
            search_allusertransactions.records.length ? search_allusertransactions.records : [],
        ) */

        if (search_allusertransactions) {
            if (search_allusertransactions.records.length) {
                const all_trans = []
                for (let i = 0; i < search_allusertransactions.records.length; i++) {
                    if (search_allusertransactions.records[i].transaction_status) {
                        let transaction_status = search_allusertransactions.records[
                            i
                        ].transaction_status.toLowerCase()

                        let transaction_status_cls =
                            transaction_status == 'funded' ||
                            transaction_status == 'settled' ||
                            transaction_status == 'unwind settled'
                                ? 'green'
                                : 'red'

                        transaction_status =
                            transaction_status == 'funded' || transaction_status == 'settled'
                                ? 'Payment Complete'
                                : transaction_status == 'returned'
                                ? 'Declined'
                                : transaction_status == 'canceled'
                                ? 'Cancelled'
                                : transaction_status == 'unwind pending'
                                ? 'Refund Pending'
                                : transaction_status == 'unwind settled'
                                ? 'Refund Complete'
                                : transaction_status == 'chargeback'
                                ? 'Chargeback'
                                : 'Pending'

                        search_allusertransactions.records[
                            i
                        ].transaction_status = transaction_status
                        search_allusertransactions.records[
                            i
                        ].transaction_status_cls = transaction_status_cls
                    } else {
                        if (
                            search_allusertransactions.records[i].transaction_type &&
                            search_allusertransactions.records[i].transaction_type == 'Refund'
                        ) {
                            if (
                                all_trans.indexOf(
                                    search_allusertransactions.records[i].buynow_cart_id,
                                ) != -1
                            ) {
                                search_allusertransactions.records[i].payment_status = 'Declined'
                                /* search_allusertransactions.records[i].created_at =
                                search_allusertransactions.records[i].created_at1 */
                            } else {
                                search_allusertransactions.records[i].payment_status =
                                    search_allusertransactions.records[
                                        i
                                    ].buynow_payment_status.toLowerCase() == 'unwind settled'
                                        ? 'Refund Complete'
                                        : 'Refund Pending'
                            }
                            all_trans.push(search_allusertransactions.records[i].buynow_cart_id)
                        } else if (
                            search_allusertransactions.records[i].transaction_type &&
                            search_allusertransactions.records[i].transaction_type == 'invoice'
                        ) {
                            if (
                                all_trans.indexOf(
                                    search_allusertransactions.records[i].buynow_cart_id,
                                ) != -1 ||
                                (search_allusertransactions.records[i].buynow_paid == '0' &&
                                    search_allusertransactions.records[i].buynow_paid_amount == '0')
                            ) {
                                search_allusertransactions.records[i].payment_status = 'Declined'
                                /* search_allusertransactions.records[i].created_at =
                                search_allusertransactions.records[i].created_at1 */
                            } else {
                                if (search_allusertransactions.records[i].buynow_paid == 1) {
                                    search_allusertransactions.records[i].payment_status = 'Success'
                                } else {
                                    search_allusertransactions.records[i].payment_status = 'Pending'
                                }
                                all_trans.push(search_allusertransactions.records[i].buynow_cart_id)
                            }
                        } else {
                            search_allusertransactions.records[i].payment_status = 'Success'
                        }
                    }
                }
                setRecentTransactions(search_allusertransactions.records)
            } else {
                setRecentTransactions([])
            }
        }
    }, [search_allusertransactions])

    const findInvestmentFilterDates = () => {
        let startDate = new Date()
        let endDate = new Date()
        formikRoyalty.values.chartStartDate = ''
        formikRoyalty.values.chartEndDate = ''

        let filterVal = formikRoyalty.values.investment_quarter_dropdown
        console.log(filterVal)
        if (filterVal != 'life_time') {
            startDate = new Date(startDate.setDate(startDate.getDate() - filterVal))

            if (startDate && endDate) {
                startDate =
                    startDate.getFullYear() +
                    '-' +
                    (startDate.getMonth() + 1) +
                    '-' +
                    startDate.getDate()
                endDate =
                    endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate()
            }

            formikRoyalty.values.chartStartDate = startDate
            formikRoyalty.values.chartEndDate = endDate
        }
    }

    const findQuarterDates = (lastQuarter, useChartDate, ty) => {
        let now = new Date()

        let quarter = 0
        let startDate
        let endDate

        let filterVal = ''
        if (ty == 'royalty') {
            filterVal = formikRoyalty.values.royalty_quarter_dropdown
        } else if (ty == 'investment') {
            filterVal = formikRoyalty.values.investment_quarter_dropdown
        }

        if (lastQuarter || filterVal == 'last_quarter') {
            quarter = Math.floor(currentDate.getMonth() / 3) - 1
        } else {
            quarter = filterVal
        }

        if (
            !lastQuarter &&
            (filterVal == 'year' || filterVal == 'life_time' || filterVal == 'last_4_quarters')
        ) {
            if (filterVal == 'year') {
                startDate = new Date(currentDate.getFullYear() + '-1-1')

                endDate = currentDate //new Date(currentDate.getFullYear() + '-12-31')
            } else if (filterVal == 'last_4_quarters') {
                startDate = new Date()
                startDate = new Date(startDate.setFullYear(startDate.getFullYear() - 1))
                endDate = currentDate
            }
        } else {
            startDate = new Date(currentDate.getFullYear(), quarter * 3, 1)
            endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 3, 0)
        }

        if (startDate && endDate) {
            startDate =
                startDate.getFullYear() +
                '-' +
                (startDate.getMonth() + 1) +
                '-' +
                startDate.getDate()
            endDate =
                endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate()
        }

        if (!useChartDate) {
            formikRoyalty.values.lastQuarterStartDate = startDate
            formikRoyalty.values.lastQuarterEndDate = endDate
        } else {
            formikRoyalty.values.chartStartDate = startDate
            formikRoyalty.values.chartEndDate = endDate
        }
    }

    const setQarterDateSelect = (ty) => {
        let filterVal =
            ty == 'royalty'
                ? formikRoyalty.values.royalty_quarter_dropdown
                : formikRoyalty.values.investment_quarter_dropdown
        //if (formik.values.royalty_quarter_dropdown == 'last_quarter') {
        if (filterVal == 'last_quarter') {
            findQuarterDates(true, true, ty)
        } else {
            findQuarterDates(false, true, ty)
        }
    }

    useEffect(() => {
        if (formikRoyalty.values.song_share != '' && user?.id) {
            if (songshares_list?.records?.length) {
                let result = songshares_list.records.find(
                    (data) => data.id == formikRoyalty.values.song_share,
                )
                setSongshareTitle(result?.title)
            }
            setQarterDateSelect('royalty')
            // getRoyaltyChartData(formikRoyalty.values)

            //setQarterDateSelect('investment')
            findInvestmentFilterDates()
            getInvestementChartData(formikRoyalty.values)
            //getDashboardData(formik.values)
        }
    }, [formikRoyalty.values.song_share, user])

    const royaltyChart = (canvas) => {
        const ctx = canvas.getContext('2d')

        //1. Using gradient background.
        let gradient = ctx.createLinearGradient(0, 0, 0, 150)
        gradient.addColorStop(0, 'rgba(180, 201, 227, 1)')
        gradient.addColorStop(0.4, 'rgba(180, 201, 227, 0.5)')
        gradient.addColorStop(1, 'rgba(180, 201, 227, 0.1)')

        const result = {
            labels: royaltyChartLabel?.length != undefined ? royaltyChartLabel : ['0'],
            datasets: [
                {
                    backgroundColor: gradient,
                    pointBackgroundColor: 'rgba(48, 108, 174, 1)',
                    data: royaltyChartData?.length != undefined ? royaltyChartData : [0],
                    cubicInterpolationMode: 'monotone',
                },
            ],
        }

        return result
    }

    useEffect(() => {
        if (user?.id) {
            setQarterDateSelect('royalty')
            // getRoyaltyChartData(formikRoyalty.values)
        }
    }, [formikRoyalty.values.royalty_quarter_dropdown, user])

    useEffect(() => {
        if (productLoaded) {
            if (search_allproducts.records.length) {
                search_allproducts.records.map((data, index) => {
                    if (parseInt(data.id) == parseInt(props.match.params.id)) {
                        setKey(index)
                        if (private_sale && private_sale == 1 && !isAuthenticated) {
                            setShowPrivateSale(true)
                            // setAlert(
                            //     'It`s a Private listing,
                            // Only registered private users can see.If you want to join please send and email to support@songvest.zohodesk.com',
                            //     'error',
                            // )
                            // history.push('/search')
                        } else {
                            setShowPrivateSale(false)
                            setViewProduct(
                                search_allproducts.records.length ? search_allproducts.records : '',
                            )
                        }

                        if (trigger && window._learnq && window._learnq?._loaded) {
                            let item = search_allproducts.records[index]
                            var _learnq = window._learnq || []
                            var itemArr = {
                                ProductName: item.title,
                                ProductID: item.id,
                                Categories:
                                    item.auctiontype === 'live'
                                        ? 'Auction'
                                        : item.auctiontype === 'buynow'
                                        ? 'SongShares'
                                        : 'VIP Auction',
                                URL: window.location.href,
                                Price: item.auctiontype === 'buynow' ? item.bprice : item.wprice,
                            }
                            _learnq.push(['track', 'Viewed Product', itemArr])
                            setTrigger(false)
                        }
                    }
                })
            } else if (search_allproducts.setDisp != '' && search_allproducts.records.length == 0) {
                // if (private_sale && private_sale == 1) {
                //     setAlert(
                //         'It`s a private listing, only registered private users can see. If you want to join please send and email to support@songvest.zohodesk.com',
                //         'error',
                //     )
                // }
                // history.push('/search')
                setShowPrivateSale(true)
            }
            setIsLoading(false)
        }
    }, [search_allproducts])

    const favTrigger = (id) => {
        //let item = search_allproducts.records[index]
        let item =
            typeof index === 'undefined'
                ? search_allproducts.records[0]
                : search_allproducts.records[index]
        var _learnq = window._learnq || []
        var itemArr = {
            ProductName: item.title,
            ProductID: item.id,
            Categories:
                item.auctiontype === 'live'
                    ? 'Auction'
                    : item.auctiontype === 'buynow'
                    ? 'SongShares'
                    : 'VIP Auction',
            URL: window.location.href,
            Price: item.auctiontype === 'buynow' ? item.bprice : item.wprice,
        }
        _learnq.push(['track', 'Added to Favorites', itemArr])
        // updateProfile({
        //     email: user.email,
        //     Favorite: item.title,
        // })
    }

    const autoPayData = [
        {
            label: 'Auto Purchase is Active',
            name: 'autopurchase',
            type: 'checkbox',
            class: `col-12`,
            formik: formikAuto,
        },
    ]

    useEffect(() => {
        if (viewProduct.length) {
            if (viewProduct[0].future_active === 1) {
                setEvent({
                    title: viewProduct[0].title,
                    description: viewProduct[0].short_desc,
                    start: viewProduct[0].date_added,
                    end: viewProduct[0].date_closed,
                    url: window.location.href,
                })
            }
            if (
                viewProduct[0].myBids &&
                viewProduct[0].myBids.length &&
                viewProduct[0].market_status === 'sold'
            ) {
                let totalShares = 0
                viewProduct[0].myBids.forEach((bid) => {
                    totalShares += bid.proposed_amount
                })
                setAllTotalShares(totalShares)
            }
            if (
                viewProduct[0].totalBidAmount &&
                viewProduct[0].totalBidAmount.totalProposedAmount &&
                viewProduct[0].totalBidAmount.totalProposedAmount > 0
            ) {
                setAllTotalShares(viewProduct[0].totalBidAmount.totalProposedAmount)
            }
        }
        return () => {
            setEvent(null)
        }
    }, [viewProduct])
    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)
    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })

    const handler = (message, type) => {
        if (userRef.current.id === parseInt(message.bpop_cbidder)) {
            setNormalProxy(false)
        }
        if (viewProductRef.current)
            messageHandler(
                message,
                viewProductRef.current,
                userRef.current,
                setAlert,
                setViewProduct,
                type,
                setBidHistoryChange,
            )
    }

    const ttwHandler = (message, type) => {
        // if (viewProductRef.current)
        if (
            userRef &&
            userRef.current.id === parseInt(message.bpop_cbidder) &&
            message.bidding_type &&
            message.bidding_type === 'proxy'
        ) {
            setMkProxy(false)
            // setIsLoading(true)
            // getAllSearchProducts(search)
        }

        ttwMessageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }

    const handleBuy = (data) => {
        if (data) {
            window.open(data, '_blank')
        }
    }

    useEffect(() => {
        const socket = openSocket(global.site_url, { transports: ['websocket'] })
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
            if (
                viewProduct &&
                viewProduct[key] &&
                viewProduct[key].auctiontype === 'live' &&
                Number(viewProduct[key].id) === Number(data.id)
            ) {
                setIsLoading(true)
                getAllSearchProducts({
                    ...search,
                    filters: {
                        ...search.filters,
                        id: {
                            ...search.filters.id,
                            value: data.id,
                        },
                    },
                })
            }
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })
        socket.on('featureProxyList', (data) => {
            ttwHandler(data, 'featureProxyList')
        })
        socket.on('ttwBidAddtime', (data) => {
            ttwHandler(data, 'ttwBidAddtime')
        })
        socket.on('cartTimer', (data) => {
            if (
                isAuthenticated &&
                data &&
                (Number(data.product_id) === Number(props.match.params.id) ||
                    Number(data.project_id) === Number(props.match.params.id)) &&
                data.user_id !== user.id
            ) {
                setIsLoading(true)
                getAllSearchProducts({
                    ...search,
                    filters: {
                        ...search.filters,
                        id: {
                            ...search.filters.id,
                            value: data.product_id,
                        },
                    },
                })
            }
        })
        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
            socket.off('featureProxyList', (data) => {
                ttwHandler(data, 'featureProxyList')
            })
            socket.off('ttwBidAddtime', (data) => {
                ttwHandler(data, 'ttwBidAddtime')
            })
            socket.off('cartTimer', (data) => {
                ttwHandler(data, 'ttwBidAddtime')
            })
            socket.disconnect()
        }
    }, [])

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
    }

    const [userHaveHardBid, setUserHaveHardBid] = useState(false)

    useEffect(() => {
        if (user && user.id && viewProduct[key]) {
            if (viewProduct[key].auctiontype === 'ttw') {
                getAllTtwBidHistoryByUser({
                    id: viewProduct[key].id,
                    user_id: user.id,
                    limit: 1000,
                    page: 1,
                })

                checkBuyAllNowShareRequestSent({
                    userId: user.id,
                    projectId: viewProduct[key].id,
                })

                setUserHaveHardBid(false)
                if (
                    viewProduct[key].auctiontype == 'ttw' &&
                    viewProduct[key].market_status === 'open'
                ) {
                    Object.keys(viewProduct[key].ttwBidsObject).map((increment, objIndex) => {
                        if (
                            viewProduct[key].ttwBidsObject[increment].findIndex(
                                (data) => data.user_id === user.id,
                            ) > -1 &&
                            ((viewProduct[key].isUserHaveHardBid &&
                                viewProduct[key].isUserHaveHardBid.indexOf(user.id) > -1) ||
                                (viewProduct[key].proxy_bid_details.users.indexOf(user.id) === -1 &&
                                    viewProduct[key].ttwBids.findIndex(
                                        (bid) => bid.user_id === user.id,
                                    ) > -1))
                        ) {
                            setUserHaveHardBid(true)
                        }
                    })
                }
            } else if (viewProduct[key].private_sale === 1) {
                checkBuyAllNowShareRequestSent({
                    userId: user.id,
                    projectId: viewProduct[key].id,
                })
            }
        } else {
            getAllTtwBidHistoryByUser({
                id: 0,
                user_id: 0,
                limit: 0,
                page: 1,
            })
        }
    }, [viewProduct[key]])

    const [sentBuyAllNowRequest, setSentBuyAllNowRequest] = useState(false)

    useEffect(() => {
        if (
            checkBuyAllNowShareRequestSentResponse &&
            checkBuyAllNowShareRequestSentResponse.from == 'buyAllNowShareRequestSent'
        ) {
            if (
                checkBuyAllNowShareRequestSentResponse.data &&
                checkBuyAllNowShareRequestSentResponse.data.records &&
                (checkBuyAllNowShareRequestSentResponse.data.records.lenght > 0 ||
                    Object.keys(checkBuyAllNowShareRequestSentResponse.data.records).length > 0)
            ) {
                setSentBuyAllNowRequest(true)
            } else {
                setSentBuyAllNowRequest(false)
            }
        }
    }, [checkBuyAllNowShareRequestSentResponse])

    const [iniProposed, setIniPropsed] = useState(0)

    const compare = (a, b) => {
        if (a.id < b.id) return -1
        if (a.id > b.id) return 1
        return 0
    }

    const validationArray = [
        Yup.object({
            email: Yup.string()
                .email('Invalid email format')
                .max(250, 'Maximum 250 characters')
                .required('Required!'),
        }),
    ]

    const zohoFormik = useFormik({
        initialValues: {
            email: '',
            utm_source: localStorage.getItem('utm_source') || '',
            utm_medium: localStorage.getItem('utm_medium') || '',
            utm_campaign: localStorage.getItem('utm_campaign') || '',
        },
        validationSchema: validationArray[0],
        onSubmit: (values) => {
            addCampaignContact(values, 'User', true, true)
        },
    })

    const zohoUpdate = [
        {
            name: 'email',
            type: 'email',
            placeholder: 'Enter your email address',
            class: 'col-md-8 col-sm-12 mx-auto',
            formik: zohoFormik,
            upperLabel: true,
        },
    ]

    useEffect(() => {
        if (responseStatusCampaign) {
            if (responseStatusCampaign.from === 'addCampaignContact') {
                setAlert('Successfully updated', 'success')
                setCampaignSignup(false)
                // window.location.href = 'https://www.songvest.com/registered'
            }
        }
    }, [responseStatusCampaign])

    const executeScroll = () => myRef.current.scrollIntoView()

    const [buyAllShareModal, showBuyAllShareModal] = useState(false)
    const [campaignSignup, setCampaignSignup] = useState(true)

    useEffect(() => {
        setCampaignSignup(true)
    }, [])

    const buyAllSharevalidationArray = [
        Yup.object({
            email: Yup.string()
                .email('Invalid email format')
                .max(250, 'Maximum 250 characters')
                .required('Required!'),
            amount: Yup.string().required('Required!'),
        }),
    ]

    const [submittingBuyAllShareForm, setSubmittingBuyAllShareForm] = useState(false)

    const buyAllShareFormik = useFormik({
        initialValues: {
            email: '',
            amount: '',
            comment: '',
        },
        validationSchema: buyAllSharevalidationArray[0],
        onSubmit: (values) => {
            setSubmittingBuyAllShareForm(true)
            // let postData = {
            //     projectId: viewProduct[key].id,
            //     userId: user.user_id,
            //     qty: viewProduct[key].qty,
            // }

            // notifyBuyAllNowShareAction(postData)
        },
    })

    useEffect(() => {
        if (buyAllShareModal) {
            setSubmittingBuyAllShareForm(false)
            buyAllShareFormik.setFieldValue(
                'amount',
                currencyFormatTwoNoDollar(viewProduct[key].sprice),
            )
        }
    }, [buyAllShareModal])

    const dateRangeDroptwo = [
        {
            placeholder: 'Please Select',
            class: `ddVal`,
            type: 'select',
            options: [
                // { value: '0', show: 'First Quarter' },
                // { value: '1', show: 'Second Quarter' },
                // { value: '2', show: 'Third Quarter' },
                // { value: '3', show: 'Fourth Quarter' },
                { value: 'year', show: 'Year To Date' },
                { value: currentQuarter, show: 'Current Quarter' },
                { value: 'last_quarter', show: 'Last Quarter' },
                { value: 'last_4_quarters', show: 'Last 4 Quarters' },
                { value: 'life_time', show: 'Lifetime' },
            ],
            name: 'royalty_quarter_dropdown',
            formik: formikRoyalty,
            upperLabel: true,
        },
    ]

    useEffect(() => {
        if (user) {
            buyAllShareFormik.values.email = user.email ? user.email : ''
        }
    }, [user])

    useEffect(() => {
        if (notifyBuyAllSharesResponse && notifyBuyAllSharesResponse.from == 'buyAllSharesNow') {
            if (notifyBuyAllSharesResponse.status === 'success') {
                const messageResponse = `${notifyBuyAllSharesResponse.message}`
                closeBuyAllSharesModal()
                setAlert(messageResponse, 'success')
                clearBuyAllSharesResponse()

                setIsLoading(true)
                getAllSearchProducts(search)
            }
        }
    }, [notifyBuyAllSharesResponse])

    const closeBuyAllSharesModal = () => {
        setSubmittingBuyAllShareForm(false)
        //buyAllShareFormik.values.amount = ''
        showBuyAllShareModal(false)
    }

    const buyAllshareInput = [
        /*{
            name: 'email',
            type: 'email',
            placeholder: 'Enter your email address',
            class: 'col-md-6 col-sm-12 mx-auto',
            formik: buyAllShareFormik,
            upperLabel: true,
        },*/
        {
            name: 'amount',
            type: 'number',
            placeholder: 'Enter your amount',
            class: `col-md-12 col-sm-12 pl-0 mx-auto ${
                private_sale && typeof private_sale == 'string' ? 'd-none' : ''
            }`,
            formik: buyAllShareFormik,
            upperLabel: true,
            //label: viewProduct[key]?.qty + 'at $',
        },
        {
            name: 'comment',
            type: 'textarea',
            placeholder: 'Enter your comment',
            class: `col-md-12 col-sm-12 mx-auto ${
                private_sale && typeof private_sale == 'string' ? '' : 'd-none'
            }`,
            formik: buyAllShareFormik,
            upperLabel: true,
            //label: 'Comment',
        },
    ]

    const handlePrint = (data) => {
        const printWindow = window.open('', '_blank')
        const imageElement = new Image()
        imageElement.src = data
        imageElement.alt = 'Full Size'
        imageElement.style.width = '100%'
        imageElement.style.height = '250'

        imageElement.onload = () => {
            printWindow.document.write(
                '<html><head><title>Print</title></head><body></body></html>',
            )
            printWindow.document.body.appendChild(imageElement)
            printWindow.document.close()
            setTimeout(() => {
                printWindow.print()
            }, 1000)
        }
    }

    useEffect(() => {
        if (royalty_chart_data) {
            let ch_data = [0]
            let ch_label = ['']

            let totalChartData = royalty_chart_data?.records?.length
                ? royalty_chart_data?.records?.length
                : 0

            if (totalChartData > 1 || totalChartData == 0) {
                ch_data = []
                ch_label = []
            }

            totalChartData &&
                royalty_chart_data.records.map((item, i) => {
                    ch_data.push(item.amount)
                    ch_label.push(item.paidDate)
                })

            if (totalChartData && totalChartData <= 1) {
                ch_data.push(null)
                ch_label.push('')
            }

            /* if (ch_label.length) {
                if (!ch_label.includes('2022 Q1')) {
                    ch_data.unshift(0)
                    ch_label.unshift('2022 Q1')
                }
            } */

            setRoyaltyChartData(ch_data)
            setRoyaltyChartLabel(ch_label)
        }
    }, [royalty_chart_data])

    const triggerNotifyBuyAllNowShareAction = () => {
        if (
            !buyAllShareFormik.values.amount ||
            buyAllShareFormik.values.amount <= 0 ||
            (isNaN(buyAllShareFormik.values.amount) && viewProduct[key].private_sale === 0)
        ) {
            setAlert('Check your amount', 'error')
        } else {
            let postData = {
                projectId: viewProduct[key].id,
                userId: user.user_id,
                //qty: viewProduct[key].qty,
                pricePerUnit:
                    viewProduct[key].private_sale === 0 ? buyAllShareFormik.values.amount : 0,
                privateSale: viewProduct[key].private_sale,
                comment: buyAllShareFormik.values.comment,
            }

            notifyBuyAllNowShareAction(postData)
        }
    }

    return (
        <AppLayout
            productType={viewProduct[key]?.auctiontype}
            productBanner={viewProduct[key]?.bannerimage}
            isLoading={isLoading}
        >
            <>
                <div className="productContainer" id="productView">
                    {isLoading ? (
                        <Loaders name="product_view" isLoading={isLoading} loop={1} />
                    ) : showPrivateSale ? (
                        <div className="privateListingCnt text-center">
                            <img src="/assets/svg/private2.svg" alt="private auction" />
                            <h2>
                                This is a Private listing. Only registered private users can view.
                                <br />
                            </h2>
                            <h3>If you want to join please send an email to:</h3>
                            <a href="mailto: support@songvest.zohodesk.com">
                                support@songvest.zohodesk.com
                            </a>
                        </div>
                    ) : viewProduct.length ? (
                        <>
                            <MetaTags
                                title={viewProduct[key].title}
                                avatar={viewProduct[key].avatar}
                                siteName="SongVest"
                                id={viewProduct[key].id}
                            />
                            {viewProduct[key].auctiontype == 'ttw' ? (
                                <div
                                    className={`pdtTopContainer pt-5 ${
                                        viewProduct[key].auctiontype == 'ttw'
                                            ? 'offrngWrapper vipAuc'
                                            : viewProduct[key].auctiontype == 'buynow'
                                            ? 'ofrPdtWrpr'
                                            : 'rglrActnCntnr'
                                    }`}
                                >
                                    <div className="pvTimerCnt">
                                        {viewProduct[key].auctiontype != 'buynow' ? (
                                            <>
                                                {viewProduct[key].market_status === 'open' ? (
                                                    <div className="pvTimerBox extndRuleCntnr">
                                                        <div className="hlpTltpCntnr">
                                                            <CustomPopover
                                                                label={<HelpIcon />}
                                                                content={`If any bid is made during the last ${allConfigurationVariables.ext_min_limit} minutes, the timer will extend by ${allConfigurationVariables.bidding_extended} minutes to give everyone a chance to bid.`}
                                                                // content={`Reserving a share places a bid on the share and creates a reservation for the offering. You will be able to purchase reserved shares when they go on sale (subject to SEC qualification). If you are outbid prior to onsale, we will notify you via email to adjust your bid in order to keep your reservation.`}
                                                            />
                                                        </div>
                                                        <Timer
                                                            date_added={viewProduct[key].date_added}
                                                            date_closed={
                                                                viewProduct[key].date_closed
                                                            }
                                                            withText={1}
                                                            endText="Reserve Period Ends In: "
                                                            startText="Reserve Period Starts in: "
                                                        ></Timer>
                                                    </div>
                                                ) : (
                                                    <>
                                                        {viewProduct[key].auctiontype === 'ttw' &&
                                                        viewProduct[key].market_status === 'sold' &&
                                                        !vwSmDevice ? (
                                                            <div className="pvTimerBox">
                                                                <label>Closed on</label>
                                                                <h4>
                                                                    {dateFormatFront(
                                                                        viewProduct[key]
                                                                            .date_closed,
                                                                    )}
                                                                </h4>
                                                            </div>
                                                        ) : null}
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    {isAuthenticated && (
                                        <>
                                            {viewProduct[key].bidtopstatus === 'outbid' && (
                                                <div className="pvBidStatus mb-2">
                                                    <h4 className="outbid">
                                                        {'YOU HAVE BEEN OUTBID'}.
                                                    </h4>
                                                </div>
                                            )}
                                            {viewProduct[key].bidtopstatus === 'winner' && (
                                                <div className="pvBidStatus mb-2">
                                                    <h4 className="winning">
                                                        {'YOU ARE WINNING THIS ITEM'}.
                                                    </h4>
                                                </div>
                                            )}
                                            {viewProduct[key].market_status === 'sold' &&
                                                myBids.length > 0 && (
                                                    <div className="pvBidStatus mb-2">
                                                        {myBids[0].proposed_amount > 0 && (
                                                            <h4 className="won">
                                                                {'CONGRATULATIONS'}
                                                            </h4>
                                                        )}
                                                    </div>
                                                )}
                                            {viewProduct[key].bidtopstatus === 'lost' && (
                                                <div className="pvBidStatus mb-2">
                                                    <h4 className="lost">
                                                        UNFORTUNATELY, YOU LOST THIS ITEM
                                                    </h4>
                                                </div>
                                            )}
                                        </>
                                    )}
                                    <div className="mnTpCntnr mnTpforOfrntBuy">
                                        <div className="lftContainer">
                                            <div className="d-flex align-items-start">
                                                <div className="pdtImg">
                                                    <img
                                                        src={
                                                            global.images_url +
                                                            viewProduct[key].avatar
                                                        }
                                                    />
                                                </div>
                                                <div className="pdtTxtInfo pl-4 text-left d-flex align-items-end justify-content-between w-100">
                                                    <div className="w-100">
                                                        <div className="pdtNmInfoWrpr d-flex align-items-center mt-0 mb-2">
                                                            <h2 className="pdtName">
                                                                {viewProduct[key].title}
                                                            </h2>
                                                        </div>
                                                        {/* <ViewDescription /> */}
                                                        <div className="pvAbt shortDesc">
                                                            <ReadMore
                                                                data={
                                                                    viewProduct[key].auctiontype ==
                                                                    'buynow'
                                                                        ? viewProduct[key]
                                                                              ?.about_short_description
                                                                        : viewProduct[key]
                                                                              ?.short_desc
                                                                }
                                                                limit={150}
                                                                className="pdtAbtCntnr"
                                                            />
                                                        </div>
                                                        {/* <h6 className="ttwRoyaltiesAmt">
                                                            Royalties Paid Per Unit (last 12 months)
                                                            <span>
                                                                {currencyFormatTwo(
                                                                    viewProduct[key]
                                                                        .annual_royalties /
                                                                        viewProduct[key].qty,
                                                                )}
                                                            </span>
                                                        </h6> */}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* TO BE ADDED HERE */}
                                            <div className="bdngDtlWrapper">
                                                <div className="placeBidWrpr">
                                                    <div className="prxyBdStsBr mt-3">
                                                        {viewProduct[key].auctiontype == 'ttw' &&
                                                            viewProduct[key].market_status ===
                                                                'open' && (
                                                                <>
                                                                    <div className="stsMtrWrapper w-100 text-left">
                                                                        {user &&
                                                                            Object.keys(
                                                                                viewProduct[key]
                                                                                    .ttwBidsObject,
                                                                            ).map(
                                                                                (
                                                                                    increment,
                                                                                    objIndex,
                                                                                ) =>
                                                                                    viewProduct[
                                                                                        key
                                                                                    ].ttwBidsObject[
                                                                                        increment
                                                                                    ].findIndex(
                                                                                        (data) =>
                                                                                            data.user_id ===
                                                                                            user.id,
                                                                                    ) > -1 &&
                                                                                    ((viewProduct[
                                                                                        key
                                                                                    ]
                                                                                        .isUserHaveHardBid &&
                                                                                        viewProduct[
                                                                                            key
                                                                                        ].isUserHaveHardBid.indexOf(
                                                                                            user.id,
                                                                                        ) > -1) ||
                                                                                        (viewProduct[
                                                                                            key
                                                                                        ].proxy_bid_details.users.indexOf(
                                                                                            user.id,
                                                                                        ) === -1 &&
                                                                                            viewProduct[
                                                                                                key
                                                                                            ].ttwBids.findIndex(
                                                                                                (
                                                                                                    bid,
                                                                                                ) =>
                                                                                                    bid.user_id ===
                                                                                                    user.id,
                                                                                            ) >
                                                                                                -1)) && (
                                                                                        <>
                                                                                            {/* previous status wrapper */}

                                                                                            {increment <
                                                                                                viewProduct[
                                                                                                    key
                                                                                                ]
                                                                                                    .currentbid_increment && (
                                                                                                <>
                                                                                                    <div className="bidBarInfo">
                                                                                                        {' '}
                                                                                                        <p className="prcLvlTxt">
                                                                                                            <b>
                                                                                                                Price
                                                                                                                level:{' '}
                                                                                                                {currencyFormatInt(
                                                                                                                    increment,
                                                                                                                    // [key].currenviewProducttbid_increment,
                                                                                                                )}
                                                                                                            </b>
                                                                                                        </p>
                                                                                                        <div className="helpIcon">
                                                                                                            <CustomPopover
                                                                                                                label={
                                                                                                                    <HelpIcon />
                                                                                                                }
                                                                                                                content="This bar shows the placement of your bid as it relates to priority. As bids come in at higher prices per unit, the lower priority bids are knocked out first and the higher priority bids are the last to be lost."
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="shrStsWrpr d-flex align-items-center w-100 mt-1 mb-4">
                                                                                                        <img src="/assets/svg/thumb_down.svg" />
                                                                                                        <div
                                                                                                            className={`stsBxs d-flex w-100 ${
                                                                                                                increment !=
                                                                                                                viewProduct[
                                                                                                                    key
                                                                                                                ]
                                                                                                                    .currentbid_increment
                                                                                                                    ? 'justify-content-end'
                                                                                                                    : 'justify-content-end'
                                                                                                            }`}
                                                                                                        >
                                                                                                            <div
                                                                                                                className={`dmyStstusBox ${
                                                                                                                    viewProduct[
                                                                                                                        key
                                                                                                                    ]
                                                                                                                        .ttwBidsObject[
                                                                                                                        increment
                                                                                                                    ][
                                                                                                                        viewProduct[
                                                                                                                            key
                                                                                                                        ]
                                                                                                                            .ttwBidsObject[
                                                                                                                            increment
                                                                                                                        ]
                                                                                                                            .length -
                                                                                                                            1
                                                                                                                    ]
                                                                                                                        .init_proposed_amount ===
                                                                                                                    viewProduct[
                                                                                                                        key
                                                                                                                    ]
                                                                                                                        .ttwBidsObject[
                                                                                                                        increment
                                                                                                                    ][
                                                                                                                        viewProduct[
                                                                                                                            key
                                                                                                                        ]
                                                                                                                            .ttwBidsObject[
                                                                                                                            increment
                                                                                                                        ]
                                                                                                                            .length -
                                                                                                                            1
                                                                                                                    ]
                                                                                                                        .proposed_amount
                                                                                                                        ? 'ngtvWrpr'
                                                                                                                        : 'ngtvWrprEmpty'
                                                                                                                }`}
                                                                                                                style={{
                                                                                                                    width:
                                                                                                                        '100%',
                                                                                                                    background:
                                                                                                                        '#1a1a1a',
                                                                                                                }}
                                                                                                            ></div>
                                                                                                            {[
                                                                                                                ...viewProduct[
                                                                                                                    key
                                                                                                                ]
                                                                                                                    .ttwBidsObject[
                                                                                                                    increment
                                                                                                                ],
                                                                                                            ]
                                                                                                                .reverse()
                                                                                                                .map(
                                                                                                                    (
                                                                                                                        data,
                                                                                                                        index,
                                                                                                                    ) => (
                                                                                                                        <>
                                                                                                                            <div
                                                                                                                                key={
                                                                                                                                    index
                                                                                                                                }
                                                                                                                                className={`statusBox d-flex align-items-center ${
                                                                                                                                    user &&
                                                                                                                                    data.user_id ==
                                                                                                                                        user.id
                                                                                                                                        ? 'crntUser'
                                                                                                                                        : 'ntCrntUsr'
                                                                                                                                } ${
                                                                                                                                    increment !=
                                                                                                                                    viewProduct[
                                                                                                                                        key
                                                                                                                                    ]
                                                                                                                                        .currentbid_increment
                                                                                                                                        ? 'frstBdDv'
                                                                                                                                        : ''
                                                                                                                                }`}
                                                                                                                                style={{
                                                                                                                                    minWidth:
                                                                                                                                        (data.init_proposed_amount /
                                                                                                                                            viewProduct[
                                                                                                                                                key
                                                                                                                                            ]
                                                                                                                                                .qty) *
                                                                                                                                            100 +
                                                                                                                                        '%',
                                                                                                                                    maxWidth:
                                                                                                                                        (data.init_proposed_amount /
                                                                                                                                            viewProduct[
                                                                                                                                                key
                                                                                                                                            ]
                                                                                                                                                .qty) *
                                                                                                                                            100 +
                                                                                                                                        '%',
                                                                                                                                    background:
                                                                                                                                        data.user_id !==
                                                                                                                                            user.id &&
                                                                                                                                        'transparent',
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                {data.init_proposed_amount -
                                                                                                                                    data.proposed_amount !==
                                                                                                                                    0 && (
                                                                                                                                    <div
                                                                                                                                        className="ngtvWrpr"
                                                                                                                                        style={{
                                                                                                                                            width:
                                                                                                                                                ((data.init_proposed_amount -
                                                                                                                                                    data.proposed_amount) /
                                                                                                                                                    data.init_proposed_amount) *
                                                                                                                                                    100 +
                                                                                                                                                '%',
                                                                                                                                            height:
                                                                                                                                                '100%',
                                                                                                                                            border:
                                                                                                                                                '1px dashed black',
                                                                                                                                            // border:
                                                                                                                                            //     data.user_id !==
                                                                                                                                            //     user.id
                                                                                                                                            //         ? '1px dashed transparent'
                                                                                                                                            //         : '1px dashed black',
                                                                                                                                            background:
                                                                                                                                                'red',
                                                                                                                                            // background:
                                                                                                                                            //     data.user_id !==
                                                                                                                                            //     user.id
                                                                                                                                            //         ? 'transparent'
                                                                                                                                            //         : 'red',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <CustomPopover
                                                                                                                                            label={
                                                                                                                                                data.user_id ===
                                                                                                                                                    user.id &&
                                                                                                                                                '-' +
                                                                                                                                                    (data.init_proposed_amount -
                                                                                                                                                        data.proposed_amount)
                                                                                                                                            }
                                                                                                                                            content={
                                                                                                                                                '-' +
                                                                                                                                                (data.init_proposed_amount -
                                                                                                                                                    data.proposed_amount)
                                                                                                                                            }
                                                                                                                                        />
                                                                                                                                    </div>
                                                                                                                                )}
                                                                                                                                <div
                                                                                                                                    style={{
                                                                                                                                        width:
                                                                                                                                            (data.proposed_amount /
                                                                                                                                                data.init_proposed_amount) *
                                                                                                                                                100 +
                                                                                                                                            '%',
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <CustomPopover
                                                                                                                                        style={{
                                                                                                                                            opacity:
                                                                                                                                                (data.init_proposed_amount /
                                                                                                                                                    viewProduct[
                                                                                                                                                        key
                                                                                                                                                    ]
                                                                                                                                                        .qty) *
                                                                                                                                                    100 >
                                                                                                                                                '10'
                                                                                                                                                    ? '1'
                                                                                                                                                    : '0',
                                                                                                                                        }}
                                                                                                                                        label={
                                                                                                                                            data.user_id ===
                                                                                                                                                user.id &&
                                                                                                                                            data.proposed_amount
                                                                                                                                        }
                                                                                                                                        content={
                                                                                                                                            data.proposed_amount +
                                                                                                                                            ' units @ ' +
                                                                                                                                            currencyFormatInt(
                                                                                                                                                data.currentbid_increment,
                                                                                                                                            )
                                                                                                                                        }
                                                                                                                                    />
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </>
                                                                                                                    ),
                                                                                                                )}
                                                                                                        </div>
                                                                                                        <img src="/assets/svg/thumb_up.svg" />
                                                                                                    </div>
                                                                                                </>
                                                                                            )}

                                                                                            {/* current status wrapper */}

                                                                                            {increment >=
                                                                                                viewProduct[
                                                                                                    key
                                                                                                ]
                                                                                                    .currentbid_increment && (
                                                                                                <>
                                                                                                    <p className="prcLvlTxt">
                                                                                                        <b>
                                                                                                            Price
                                                                                                            level:{' '}
                                                                                                            {currencyFormatInt(
                                                                                                                increment,
                                                                                                            )}
                                                                                                        </b>
                                                                                                    </p>
                                                                                                    <div className="shrStsWrpr d-flex align-items-center w-100 mt-1 mb-4">
                                                                                                        <img src="/assets/svg/thumb_down.svg" />
                                                                                                        <div
                                                                                                            className={`stsBxs d-flex w-100 ${
                                                                                                                increment !=
                                                                                                                viewProduct[
                                                                                                                    key
                                                                                                                ]
                                                                                                                    .currentbid_increment
                                                                                                                    ? 'justify-content-end stsBxsRev'
                                                                                                                    : 'justify-content-end'
                                                                                                            }`}
                                                                                                        >
                                                                                                            {increment ==
                                                                                                                viewProduct[
                                                                                                                    key
                                                                                                                ]
                                                                                                                    .currentbid_increment && (
                                                                                                                <div
                                                                                                                    className="dmyStstusBox"
                                                                                                                    style={{
                                                                                                                        width:
                                                                                                                            ((viewProduct[
                                                                                                                                key
                                                                                                                            ]
                                                                                                                                .qty -
                                                                                                                                viewProduct[
                                                                                                                                    key
                                                                                                                                ]
                                                                                                                                    .masterQty) *
                                                                                                                                100) /
                                                                                                                                viewProduct[
                                                                                                                                    key
                                                                                                                                ]
                                                                                                                                    .qty +
                                                                                                                            '%',
                                                                                                                        background:
                                                                                                                            '#1a1a1a',
                                                                                                                    }}
                                                                                                                ></div>
                                                                                                            )}
                                                                                                            {[
                                                                                                                ...viewProduct[
                                                                                                                    key
                                                                                                                ]
                                                                                                                    .ttwBidsObject[
                                                                                                                    increment
                                                                                                                ],
                                                                                                            ]
                                                                                                                .reverse()
                                                                                                                .map(
                                                                                                                    (
                                                                                                                        data,
                                                                                                                        index,
                                                                                                                    ) => (
                                                                                                                        <>
                                                                                                                            <div
                                                                                                                                key={
                                                                                                                                    index
                                                                                                                                }
                                                                                                                                className={`statusBox d-flex align-items-center ${
                                                                                                                                    data.user_id ==
                                                                                                                                    user.id
                                                                                                                                        ? 'crntUser'
                                                                                                                                        : 'ntCrntUsr'
                                                                                                                                } ${
                                                                                                                                    increment !=
                                                                                                                                    viewProduct[
                                                                                                                                        key
                                                                                                                                    ]
                                                                                                                                        .currentbid_increment
                                                                                                                                        ? 'frstBdDv'
                                                                                                                                        : ''
                                                                                                                                }`}
                                                                                                                                style={{
                                                                                                                                    width:
                                                                                                                                        (data.proposed_amount /
                                                                                                                                            viewProduct[
                                                                                                                                                key
                                                                                                                                            ]
                                                                                                                                                .qty) *
                                                                                                                                            100 +
                                                                                                                                        '%',
                                                                                                                                    background:
                                                                                                                                        data.user_id !==
                                                                                                                                            user.id &&
                                                                                                                                        'transparent',
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                {data.user_id ===
                                                                                                                                    user.id &&
                                                                                                                                    data.init_proposed_amount -
                                                                                                                                        data.proposed_amount !==
                                                                                                                                        0 && (
                                                                                                                                        <div
                                                                                                                                            className="ngtvWrpr"
                                                                                                                                            style={{
                                                                                                                                                width:
                                                                                                                                                    ((data.init_proposed_amount -
                                                                                                                                                        data.proposed_amount) /
                                                                                                                                                        data.init_proposed_amount) *
                                                                                                                                                        100 +
                                                                                                                                                    '%',
                                                                                                                                                height:
                                                                                                                                                    '100%',
                                                                                                                                                border:
                                                                                                                                                    '1px dashed black',
                                                                                                                                                background:
                                                                                                                                                    'red',
                                                                                                                                                // border:
                                                                                                                                                //     data.user_id !==
                                                                                                                                                //     user.id
                                                                                                                                                //         ? '1px dashed transparent'
                                                                                                                                                //         : '1px dashed black',
                                                                                                                                                // background:
                                                                                                                                                //     data.user_id !==
                                                                                                                                                //     user.id
                                                                                                                                                //         ? 'transparent'
                                                                                                                                                //         : 'red',
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <CustomPopover
                                                                                                                                                label={
                                                                                                                                                    data.user_id ===
                                                                                                                                                        user.id &&
                                                                                                                                                    '-' +
                                                                                                                                                        (data.init_proposed_amount -
                                                                                                                                                            data.proposed_amount)
                                                                                                                                                }
                                                                                                                                                content={
                                                                                                                                                    '-' +
                                                                                                                                                    (data.init_proposed_amount -
                                                                                                                                                        data.proposed_amount)
                                                                                                                                                }
                                                                                                                                            />
                                                                                                                                        </div>
                                                                                                                                    )}
                                                                                                                                <div
                                                                                                                                    className="text-center"
                                                                                                                                    style={{
                                                                                                                                        width:
                                                                                                                                            (data.proposed_amount /
                                                                                                                                                data.init_proposed_amount) *
                                                                                                                                                100 +
                                                                                                                                            '%',
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <CustomPopover
                                                                                                                                        style={{
                                                                                                                                            opacity:
                                                                                                                                                (data.proposed_amount /
                                                                                                                                                    viewProduct[
                                                                                                                                                        key
                                                                                                                                                    ]
                                                                                                                                                        .qty) *
                                                                                                                                                    100 >
                                                                                                                                                '6'
                                                                                                                                                    ? '1'
                                                                                                                                                    : '0',
                                                                                                                                        }}
                                                                                                                                        label={
                                                                                                                                            data.user_id ===
                                                                                                                                                user.id &&
                                                                                                                                            data.proposed_amount
                                                                                                                                        }
                                                                                                                                        content={
                                                                                                                                            data.proposed_amount +
                                                                                                                                            ' units @ ' +
                                                                                                                                            currencyFormatInt(
                                                                                                                                                data.currentbid_increment,
                                                                                                                                            )
                                                                                                                                        }
                                                                                                                                    />
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </>
                                                                                                                    ),
                                                                                                                )}
                                                                                                        </div>
                                                                                                        <img src="/assets/svg/thumb_up.svg" />
                                                                                                    </div>
                                                                                                </>
                                                                                            )}
                                                                                        </>
                                                                                    ),
                                                                            )}
                                                                    </div>
                                                                </>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className={`rtContainer bdngDtlWrapper ${
                                                viewProduct[key].auctiontype == 'ttw' ? 'vipRt' : ''
                                            }`}
                                        >
                                            <div className="hlpTltpCntnr">
                                                <CustomPopover
                                                    label={<HelpIcon />}
                                                    content={`Reserving a share places a bid on the share and creates a reservation for the offering. You will be able to purchase reserved shares when they go on sale (subject to SEC qualification). If you are outbid prior to onsale, we will notify you via email to adjust your bid in order to keep your reservation.`}
                                                />
                                            </div>
                                            <div className="nmbrOfBdsChart w-100">
                                                <div className="sctnHdr">
                                                    {(viewProduct[key].masterQty * 100) /
                                                        viewProduct[key].qty >
                                                    75 ? (
                                                        <>
                                                            {viewProduct[key].auctiontype ==
                                                                'ttw' &&
                                                            viewProduct[key].market_status ===
                                                                'open' ? (
                                                                <div
                                                                    className={`rsrvUntWrpr ttwRsrv ${
                                                                        (viewProduct[key]
                                                                            .masterQty *
                                                                            100) /
                                                                            viewProduct[key].qty >
                                                                            50 &&
                                                                        (viewProduct[key]
                                                                            .masterQty *
                                                                            100) /
                                                                            viewProduct[key].qty <
                                                                            75
                                                                            ? 'ylwBack'
                                                                            : (viewProduct[key]
                                                                                  .masterQty *
                                                                                  100) /
                                                                                  viewProduct[key]
                                                                                      .qty >
                                                                              75
                                                                            ? 'grnBack'
                                                                            : ''
                                                                    }`}
                                                                >
                                                                    {parseFloat(
                                                                        (viewProduct[key]
                                                                            .masterQty *
                                                                            100) /
                                                                            viewProduct[key].qty,
                                                                    ).toFixed(2)}
                                                                    % of {viewProduct[key].qty}{' '}
                                                                    SongShare Goal
                                                                    <Tooltip
                                                                        placement="top"
                                                                        title={`${parseFloat(
                                                                            (viewProduct[key]
                                                                                .masterQty *
                                                                                100) /
                                                                                viewProduct[key]
                                                                                    .qty,
                                                                        ).toFixed(2)}
                                                                            % of ${
                                                                                viewProduct[key].qty
                                                                            } song shares has been reserved till now`}
                                                                    >
                                                                        <span className="material-icons ml-2">
                                                                            help
                                                                        </span>
                                                                    </Tooltip>
                                                                </div>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </div>
                                            <div className="pvRt">
                                                {isAuthenticated && totalShares > 0 ? (
                                                    <div className="pvMyUnits">
                                                        <h4>{totalShares}</h4>
                                                        {viewProduct[key].auctiontype == 'ttw' ? (
                                                            <p> My Shares</p>
                                                        ) : (
                                                            <p> My Units</p>
                                                        )}
                                                    </div>
                                                ) : null}
                                                <div className="pvUnitPrice">
                                                    <h3>
                                                        <b>
                                                            {viewProduct[key]
                                                                .currentbid_increment &&
                                                            viewProduct[key].market_status ===
                                                                'open'
                                                                ? currencyFormatTwo(
                                                                      viewProduct[key]
                                                                          .currentbid_increment,
                                                                  )
                                                                : currencyFormatTwo(
                                                                      viewProduct[key].wprice,
                                                                  )}
                                                        </b>
                                                    </h3>
                                                    {viewProduct[key].auctiontype == 'ttw' ? (
                                                        <p>{`${
                                                            viewProduct[key].auctiontype == 'ttw'
                                                                ? 'Current Bid Per Share'
                                                                : viewProduct[key].market_status ===
                                                                  'open Bid'
                                                                ? 'Current Bid'
                                                                : 'Final Bid'
                                                        }`}</p>
                                                    ) : (
                                                        <p>{`${
                                                            viewProduct[key].auctiontype == 'ttw'
                                                                ? 'Current Bid Per Share'
                                                                : viewProduct[key].market_status ===
                                                                  'open Bid'
                                                                ? 'Current Bid'
                                                                : 'Final Bid'
                                                        }`}</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="placeBidWrpr">
                                                <div className="">
                                                    {/* <div className="helpIcon">
                                                        <CustomPopover
                                                            label={<HelpIcon />}
                                                            content="This bar shows the placement of your bid as it relates to priority. As bids come in at higher prices per unit, the lower priority bids are knocked out first and the higher priority bids are the last to be lost."
                                                        />
                                                    </div> */}
                                                    {!isAuthenticated ? (
                                                        // <PrimaryButton
                                                        //     label="Login to bid"
                                                        //     btnSize="small"
                                                        //     onClick={() =>
                                                        //         handleRedirectInternal(
                                                        //             history,
                                                        //             'login?redirectTo=' +
                                                        //                 window.location.pathname,
                                                        //         )
                                                        //     }
                                                        // />
                                                        <div className="mnBdnCont">
                                                            {viewProduct[key].market_status ===
                                                                'open' &&
                                                            (!viewProduct[key].future_active ||
                                                                viewProduct[key]
                                                                    .isPrivateVisible) ? (
                                                                <>
                                                                    {viewProduct[key].auctiontype ==
                                                                    'ttw' ? (
                                                                        <>
                                                                            <div className="align-items-center">
                                                                                {viewProduct[key]
                                                                                    .auction && (
                                                                                    <>
                                                                                        <TTWBidding
                                                                                            data={
                                                                                                viewProduct[
                                                                                                    key
                                                                                                ]
                                                                                            }
                                                                                            type="hard"
                                                                                            // suffix
                                                                                            crPrice={
                                                                                                viewProduct[
                                                                                                    key
                                                                                                ]
                                                                                                    .wprice
                                                                                            }
                                                                                            totalBidPrice={
                                                                                                totalBidPrice
                                                                                            }
                                                                                            paramqty={
                                                                                                props
                                                                                                    ?.match
                                                                                                    ?.params
                                                                                                    ?.qty
                                                                                                    ? props
                                                                                                          ?.match
                                                                                                          ?.params
                                                                                                          ?.qty
                                                                                                    : false
                                                                                            }
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </>
                                                            ) : null}
                                                        </div>
                                                    ) : (
                                                        <div className="mnBdnCont">
                                                            {viewProduct[key].market_status ===
                                                                'open' &&
                                                            (!viewProduct[key].future_active ||
                                                                viewProduct[key]
                                                                    .isPrivateVisible) ? (
                                                                <>
                                                                    {viewProduct[key].auctiontype ==
                                                                    'ttw' ? (
                                                                        <>
                                                                            <div className="align-items-center">
                                                                                {viewProduct[key]
                                                                                    .auction &&
                                                                                    (card ||
                                                                                        bank) && (
                                                                                        <>
                                                                                            <TTWBidding
                                                                                                data={
                                                                                                    viewProduct[
                                                                                                        key
                                                                                                    ]
                                                                                                }
                                                                                                type="hard"
                                                                                                // suffix
                                                                                                crPrice={
                                                                                                    viewProduct[
                                                                                                        key
                                                                                                    ]
                                                                                                        .wprice
                                                                                                }
                                                                                                totalBidPrice={
                                                                                                    totalBidPrice
                                                                                                }
                                                                                                paramqty={
                                                                                                    props
                                                                                                        ?.match
                                                                                                        ?.params
                                                                                                        ?.qty
                                                                                                        ? props
                                                                                                              ?.match
                                                                                                              ?.params
                                                                                                              ?.qty
                                                                                                        : false
                                                                                                }
                                                                                            />
                                                                                        </>
                                                                                    )}
                                                                                {!card &&
                                                                                    !bank &&
                                                                                    user && (
                                                                                        <Link to="/paymentinfo">
                                                                                            Click
                                                                                            here to
                                                                                            add bank
                                                                                            / card
                                                                                            details
                                                                                            to bid
                                                                                            or buy
                                                                                        </Link>
                                                                                    )}
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </>
                                                            ) : null}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            {console.log(viewProduct, 'viewProduct1')}

                                            <div className="offerWatchlist">
                                                {viewProduct[key].auctiontype == 'ttw' ? (
                                                    <a
                                                        // href={
                                                        //     viewProduct[key].viewOfferingLink
                                                        //         ? viewProduct[key].viewOfferingLink
                                                        //         : 'https://www.songvest.com/offering'
                                                        // }
                                                        // rel="noreferrer"
                                                        // target="_blank"
                                                        className="vipTotal"
                                                    >
                                                        <span>{viewProduct[key].qty}</span>&nbsp;
                                                        <span>Total Shares</span>
                                                    </a>
                                                ) : (
                                                    <a
                                                        href={
                                                            viewProduct[key].viewOfferingLink
                                                                ? viewProduct[key].viewOfferingLink
                                                                : 'https://www.songvest.com/offering'
                                                        }
                                                        rel="noreferrer"
                                                        target="_blank"
                                                    >
                                                        View Offering Circular
                                                    </a>
                                                )}

                                                <FavoriteCheckbox
                                                    watchlisted={viewProduct[key].watchlistid}
                                                    project_id={viewProduct[key].id}
                                                    trigger={() => favTrigger()}
                                                    addLabel={'Add to Watchlist'}
                                                    removeLabel={'Remove Watchlist'}
                                                />
                                            </div>

                                            {viewProduct[key].auctiontype == 'ttw' &&
                                                viewProduct[key].market_status === 'open' && (
                                                    <div className="placeBidWrpr proxyBidWrapper">
                                                        {/* <div className="sctnHdr">
                                                            <h4 className="mb-0">
                                                                Make An Offer To Buy Alls
                                                            </h4>
                                                            <div className="helpIcon">
                                                                <CustomPopover
                                                                    label={<HelpIcon />}
                                                                    content="SongVest will accept offers from users to purchase the entire asset listed here, as a security or a traditional purchase, depending on the investors needs."
                                                                />
                                                            </div>
                                                        </div> */}
                                                        <div className="bdBodyContent">
                                                            <div className="prxyBdStsBr"></div>
                                                            <div className="">
                                                                {!isAuthenticated ? null : (
                                                                    <div className="mnBdnCont prxyMnBdCntnt">
                                                                        {viewProduct[key]
                                                                            .market_status ===
                                                                            'open' &&
                                                                            !viewProduct[key]
                                                                                .future_active &&
                                                                            (card || bank) && (
                                                                                <>
                                                                                    {sentBuyAllNowRequest ? (
                                                                                        <p className="text-center mt-2">
                                                                                            You have
                                                                                            sent
                                                                                            &apos;Make
                                                                                            An
                                                                                            Offer&apos;
                                                                                            request
                                                                                            for this
                                                                                            product!
                                                                                        </p>
                                                                                    ) : (
                                                                                        <>
                                                                                            <h6 className="offerCatalog">
                                                                                                Make
                                                                                                an
                                                                                                Offer
                                                                                                on
                                                                                                the
                                                                                                whole
                                                                                                Catalog{' '}
                                                                                                <span
                                                                                                    className="cursorDecoy"
                                                                                                    onClick={() =>
                                                                                                        showBuyAllShareModal(
                                                                                                            true,
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    HERE
                                                                                                </span>
                                                                                            </h6>
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                            {/* {viewProduct[key].private_sale === 1 && (
                                                <>
                                                    <div className="ofrngNrmlBd mt-3">
                                                        <div className="biddingCnt buyNnwContainer beforeLogin d-flex justify-content-start align-items-start">
                                                            {isAuthenticated && (
                                                                <PrimaryButton
                                                                    label={'Make an Offer'}
                                                                    btnSize="small"
                                                                    disabled={sentBuyAllNowRequest}
                                                                    onClick={() =>
                                                                        showBuyAllShareModal(true)
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </>
                                            )} */}
                                        </div>
                                    </div>
                                    <div className="pdtDtlContainer w-100">
                                        {viewProduct[key].auctiontype === 'ttw' &&
                                        viewProduct[key].market_status === 'sold' &&
                                        myBids.length &&
                                        viewProduct[key].bidtopstatus !== 'lost' &&
                                        viewProduct[key].bidtopstatus !== 'outbid' &&
                                        viewProduct[key].bidtopstatus !== '' ? (
                                            <div className="col-12 wonItemStatus">
                                                {myBids.map((bids, index) => (
                                                    <>
                                                        {bids.proposed_amount > 0 && (
                                                            <div
                                                                className="col-12 mx-2"
                                                                key={index + 'wonItem'}
                                                            >
                                                                <p className="won">{`You have successfully placed a non-binding bid on ${
                                                                    bids.proposed_amount
                                                                } units at ${currencyFormatInt(
                                                                    bids.currentbid_increment,
                                                                )} for a total of ${currencyFormatInt(
                                                                    bids.currentbid_increment *
                                                                        bids.proposed_amount,
                                                                )}.`}</p>
                                                            </div>
                                                        )}
                                                    </>
                                                ))}
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </div>

                                    {viewProduct[key].private_sale === 0 && (
                                        <div className="ssMessageCnt m-0">
                                            {new Date() > new Date(viewProduct[key].date_closed) ? (
                                                <div className="">
                                                    <div className="placeBidWrpr p-4 df">
                                                        {!isAuthenticated ? (
                                                            campaignSignup ? (
                                                                <>
                                                                    <p className="mb-3 text-center">
                                                                        Make sure you&apos;ve signed
                                                                        up to receive notice when
                                                                        these SongShares are
                                                                        available to purchase.
                                                                    </p>
                                                                    <form
                                                                        onSubmit={
                                                                            zohoFormik.handleSubmit
                                                                        }
                                                                        className="mt-2 ssZoho"
                                                                    >
                                                                        <div className="row zohoUpdWrpr mt-4">
                                                                            {Object.values(
                                                                                mapData(zohoUpdate),
                                                                            )}
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-6 mx-auto">
                                                                                <PrimaryButton
                                                                                    label="Keep Me Updated"
                                                                                    type="submit"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </>
                                                            ) : (
                                                                <div className="subscribeSuccess">
                                                                    <span className="material-icons">
                                                                        check_circle
                                                                    </span>
                                                                    <h5>
                                                                        Thanks for joining our
                                                                        newsletter!
                                                                    </h5>
                                                                </div>
                                                            )
                                                        ) : (
                                                            <p className="text-center mt-2">
                                                                This offering is not on sale yet.
                                                                We&apos;ll email you when it is
                                                                available!
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                            ) : null}{' '}
                                        </div>
                                    )}

                                    {isAuthenticated && viewProduct[key].private_sale === 0 ? (
                                        <div className="miscBidding">
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography className="d-flex align-items-center">
                                                        {' '}
                                                        <img src="/assets/svg/proxyicon.svg" />
                                                        MAKE A PROXY BID{' '}
                                                        <CustomPopover
                                                            label={<HelpIcon />}
                                                            content="This bar shows the placement of your bid as it relates to priority. As bids come in at higher prices per unit, the lower priority bids are knocked out first and the higher priority bids are the last to be lost."
                                                        />
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <div className="bdngDtlWrapper m-0">
                                                        {new Date() >
                                                        new Date(
                                                            viewProduct[key].date_closed,
                                                        ) ? null : (
                                                            <div className="">
                                                                <div className="placeBidWrpr proxyBidWrapper">
                                                                    <div className="bdBodyContent">
                                                                        <div className="prxyBdStsBr">
                                                                            {viewProduct[key]
                                                                                .market_status ===
                                                                                'open' &&
                                                                                user &&
                                                                                viewProduct[
                                                                                    key
                                                                                ].proxy_bid_details.users.indexOf(
                                                                                    user.id,
                                                                                ) > -1 &&
                                                                                Object.keys(
                                                                                    viewProduct[key]
                                                                                        .proxy_bid_details
                                                                                        .bids,
                                                                                ).map(
                                                                                    (
                                                                                        proxyBid,
                                                                                        index,
                                                                                    ) =>
                                                                                        proxyBid >=
                                                                                            viewProduct[
                                                                                                key
                                                                                            ]
                                                                                                .currentbid_increment && (
                                                                                            <>
                                                                                                <div className="bidBarInfo">
                                                                                                    <p className="prcLvlTxt">
                                                                                                        <b>
                                                                                                            Price
                                                                                                            level:{' '}
                                                                                                            {currencyFormatTwo(
                                                                                                                proxyBid,
                                                                                                            )}
                                                                                                        </b>
                                                                                                    </p>
                                                                                                    <div className="helpIcon">
                                                                                                        <CustomPopover
                                                                                                            label={
                                                                                                                <HelpIcon />
                                                                                                            }
                                                                                                            content="Enter the maximum amount you want to bid, and the system will place autobids for you when the price increases. "
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="shrStsWrpr d-flex align-items-center w-100 mt-1 mb-4">
                                                                                                    <img src="/assets/svg/thumb_down.svg" />
                                                                                                    <div className="stsBxs d-flex justify-content-end w-100">
                                                                                                        <div
                                                                                                            className="dmyStstusBox"
                                                                                                            style={{
                                                                                                                width:
                                                                                                                    '100%',
                                                                                                                background:
                                                                                                                    '#1a1a1a',
                                                                                                            }}
                                                                                                        ></div>
                                                                                                        {viewProduct[
                                                                                                            key
                                                                                                        ].proxy_bid_details.bids[
                                                                                                            proxyBid
                                                                                                        ]
                                                                                                            .slice(
                                                                                                                0,
                                                                                                            )
                                                                                                            .reverse()
                                                                                                            .map(
                                                                                                                (
                                                                                                                    data,
                                                                                                                    index,
                                                                                                                ) => (
                                                                                                                    <div
                                                                                                                        key={
                                                                                                                            index
                                                                                                                        }
                                                                                                                        className={`statusBox ${
                                                                                                                            data.user_id ==
                                                                                                                            user.id
                                                                                                                                ? 'crntUser'
                                                                                                                                : 'ntCrntUsr'
                                                                                                                        }`}
                                                                                                                        style={{
                                                                                                                            minWidth:
                                                                                                                                (data.maxqty /
                                                                                                                                    viewProduct[
                                                                                                                                        key
                                                                                                                                    ]
                                                                                                                                        .qty) *
                                                                                                                                    100 +
                                                                                                                                '%',
                                                                                                                            maxWidth:
                                                                                                                                (data.maxqty /
                                                                                                                                    viewProduct[
                                                                                                                                        key
                                                                                                                                    ]
                                                                                                                                        .qty) *
                                                                                                                                    100 +
                                                                                                                                '%',
                                                                                                                            background:
                                                                                                                                data.user_id !==
                                                                                                                                    user.id &&
                                                                                                                                'transparent',
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <CustomPopover
                                                                                                                            style={{
                                                                                                                                opacity:
                                                                                                                                    ((data.maxqty /
                                                                                                                                        viewProduct[
                                                                                                                                            key
                                                                                                                                        ]
                                                                                                                                            .qty) *
                                                                                                                                        100 >
                                                                                                                                        '15' &&
                                                                                                                                        vwPortSize) ||
                                                                                                                                    (!vwPortSize &&
                                                                                                                                        (data.maxqty /
                                                                                                                                            viewProduct[
                                                                                                                                                key
                                                                                                                                            ]
                                                                                                                                                .qty) *
                                                                                                                                            100 >
                                                                                                                                            '10')
                                                                                                                                        ? '1'
                                                                                                                                        : '0',
                                                                                                                            }}
                                                                                                                            label={
                                                                                                                                data.user_id ==
                                                                                                                                    user.id && (
                                                                                                                                    <>
                                                                                                                                        <img
                                                                                                                                            src="/assets/svg/proxyicon.svg"
                                                                                                                                            className="mr-1"
                                                                                                                                        />

                                                                                                                                        {
                                                                                                                                            data.maxqty
                                                                                                                                        }
                                                                                                                                    </>
                                                                                                                                )
                                                                                                                            }
                                                                                                                            content={
                                                                                                                                data.user_id ==
                                                                                                                                    user.id &&
                                                                                                                                data.maxqty +
                                                                                                                                    ' units @ ' +
                                                                                                                                    currencyFormatInt(
                                                                                                                                        data.maxamount,
                                                                                                                                    )
                                                                                                                            }
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                ),
                                                                                                            )}
                                                                                                    </div>
                                                                                                    <img src="/assets/svg/thumb_up.svg" />
                                                                                                </div>
                                                                                            </>
                                                                                        ),
                                                                                )}
                                                                        </div>
                                                                        {!isAuthenticated ? (
                                                                            <div className="mnBdnCont">
                                                                                <div className="biddingCnt buyNnwContainer beforeLogin d-flex justify-content-start align-items-start">
                                                                                    <div className="bidInput">
                                                                                        {Object.values(
                                                                                            mapData(
                                                                                                bidAmount,
                                                                                            ),
                                                                                        )}
                                                                                    </div>
                                                                                    {viewProduct[0]
                                                                                        .auctiontype ===
                                                                                    'buynow' ? (
                                                                                        <PrimaryButton
                                                                                            label="Login to buy"
                                                                                            btnSize="small"
                                                                                            onClick={() =>
                                                                                                handleRedirectInternal(
                                                                                                    history,
                                                                                                    'login?redirectTo=' +
                                                                                                        window
                                                                                                            .location
                                                                                                            .pathname,
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    ) : (
                                                                                        <PrimaryButton
                                                                                            label="Login to bid"
                                                                                            btnSize="small mt-0"
                                                                                            onClick={() =>
                                                                                                handleRedirectInternal(
                                                                                                    history,
                                                                                                    'login?redirectTo=' +
                                                                                                        window
                                                                                                            .location
                                                                                                            .pathname,
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <div className="mnBdnCont prxyMnBdCntnt">
                                                                                {viewProduct[key]
                                                                                    .market_status ===
                                                                                    'open' &&
                                                                                    !viewProduct[
                                                                                        key
                                                                                    ]
                                                                                        .future_active &&
                                                                                    (card ||
                                                                                        bank) && (
                                                                                        <>
                                                                                            <TTWBidding
                                                                                                data={
                                                                                                    viewProduct[
                                                                                                        key
                                                                                                    ]
                                                                                                }
                                                                                                type="proxy"
                                                                                            />
                                                                                            {userHaveHardBid &&
                                                                                                user &&
                                                                                                viewProduct[
                                                                                                    key
                                                                                                ]
                                                                                                    .auctiontype ==
                                                                                                    'ttw' &&
                                                                                                viewProduct[
                                                                                                    key
                                                                                                ].proxy_bid_details.users.indexOf(
                                                                                                    user.id,
                                                                                                ) >
                                                                                                    -1 && (
                                                                                                    <div
                                                                                                        className="proxyBidHelpCont mt-2"
                                                                                                        style={{
                                                                                                            fontSize:
                                                                                                                '14px',
                                                                                                        }}
                                                                                                    >
                                                                                                        The
                                                                                                        proxy
                                                                                                        bid
                                                                                                        will
                                                                                                        not
                                                                                                        be
                                                                                                        placed
                                                                                                        until
                                                                                                        the
                                                                                                        hard
                                                                                                        bid
                                                                                                        is
                                                                                                        outbid
                                                                                                    </div>
                                                                                                )}
                                                                                        </>
                                                                                    )}
                                                                                {!card &&
                                                                                    !bank &&
                                                                                    user &&
                                                                                    (viewProduct[
                                                                                        key
                                                                                    ].auctiontype ==
                                                                                    'live' ? (
                                                                                        <Link to="/paymentinfo">
                                                                                            To bid
                                                                                            you need
                                                                                            to add a
                                                                                            credit
                                                                                            card or
                                                                                            bank
                                                                                            details.
                                                                                            This is
                                                                                            only for
                                                                                            verification
                                                                                            not
                                                                                            purchase.
                                                                                        </Link>
                                                                                    ) : (
                                                                                        <Link to="/paymentinfo">
                                                                                            Click
                                                                                            here to
                                                                                            add
                                                                                            bank/
                                                                                            card
                                                                                            details
                                                                                            to bid
                                                                                            or buy
                                                                                        </Link>
                                                                                    ))}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}{' '}
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                    // onClick={() => setBidHistoryValue(viewProduct[key].id)}
                                                >
                                                    <Typography className="">
                                                        <img src="/assets/svg/bidhistory_icon.svg" />
                                                        MY BID HISTORY
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <>
                                                        {/* <div className="bdngDtlWrapper">
                                                                <div className="">
                                                                    <div className="placeBidWrpr d-flex align-items-stretch">
                                                                        <div className="crntBdStWrp">
                                                                            <div className="sctnHdr">
                                                                                <h4 className="mb-0">
                                                                                    My Current Bids
                                                                                </h4>
                                                                            </div>
                                                                            <div className="grdContent">
                                                                                <div className="grdBox">
                                                                                    <p>
                                                                                        My Reserved
                                                                                        Units
                                                                                    </p>
                                                                                    <h4>
                                                                                        {
                                                                                            totalShares
                                                                                        }
                                                                                    </h4>
                                                                                </div>
                                                                                <div className="grdBox">
                                                                                    <p>
                                                                                        My Total
                                                                                        Cost
                                                                                    </p>
                                                                                    <h4>
                                                                                        {currencyFormatInt(
                                                                                            totalBidPrice,
                                                                                        )}
                                                                                    </h4>
                                                                                </div>
                                                                                <div className="grdBox">
                                                                                    <p>
                                                                                        Simulated
                                                                                        Royalties
                                                                                        (Last 12
                                                                                        mos)
                                                                                    </p>
                                                                                    <h4>
                                                                                        {currencyFormat(
                                                                                            (viewProduct[
                                                                                                key
                                                                                            ]
                                                                                                .annual_royalties /
                                                                                                viewProduct[
                                                                                                    key
                                                                                                ]
                                                                                                    .qty) *
                                                                                                totalShares,
                                                                                        )}
                                                                                    </h4>
                                                                                </div>
                                                                            </div>
                                                                            <div className="ylowGrd">
                                                                                <div className="grdCntnr">
                                                                                    <h3>
                                                                                        {currencyFormatInt(
                                                                                            viewProduct[
                                                                                                key
                                                                                            ]
                                                                                                .annual_royalties,
                                                                                        )}
                                                                                    </h3>
                                                                                    <p>
                                                                                        Last 12
                                                                                        Months
                                                                                        Royalties
                                                                                        Paid
                                                                                    </p>
                                                                                </div>
                                                                                <div className="grdCntnr">
                                                                                    <h3>
                                                                                        {currencyFormatTwo(
                                                                                            viewProduct[
                                                                                                key
                                                                                            ]
                                                                                                .annual_royalties /
                                                                                                viewProduct[
                                                                                                    key
                                                                                                ]
                                                                                                    .qty,
                                                                                        )}
                                                                                    </h3>
                                                                                    <p>
                                                                                        Royalties
                                                                                        Paid Per
                                                                                        Unit (last
                                                                                        12 months)
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {console.log(
                                                                            viewProduct[key],
                                                                            'viewProduct[key]',
                                                                        )}
                                                                        <div className="nmbrOfBdsChart w-100">
                                                                            <div className="sctnHdr">
                                                                                <h4 className="mb-0">
                                                                                    Total SongShares
                                                                                </h4>
                                                                                {viewProduct[key]
                                                                                    .sprice ===
                                                                                viewProduct[key]
                                                                                    .currentbid_increment ? (
                                                                                    <>
                                                                                        {viewProduct[
                                                                                            key
                                                                                        ]
                                                                                            .auctiontype ==
                                                                                            'ttw' &&
                                                                                        viewProduct[
                                                                                            key
                                                                                        ]
                                                                                            .market_status ===
                                                                                            'open' ? (
                                                                                            <div
                                                                                                className={`rsrvUntWrpr ${
                                                                                                    (viewProduct[
                                                                                                        key
                                                                                                    ]
                                                                                                        .masterQty *
                                                                                                        100) /
                                                                                                        viewProduct[
                                                                                                            key
                                                                                                        ]
                                                                                                            .qty >
                                                                                                        50 &&
                                                                                                    (viewProduct[
                                                                                                        key
                                                                                                    ]
                                                                                                        .masterQty *
                                                                                                        100) /
                                                                                                        viewProduct[
                                                                                                            key
                                                                                                        ]
                                                                                                            .qty <
                                                                                                        75
                                                                                                        ? 'ylwBack'
                                                                                                        : (viewProduct[
                                                                                                              key
                                                                                                          ]
                                                                                                              .masterQty *
                                                                                                              100) /
                                                                                                              viewProduct[
                                                                                                                  key
                                                                                                              ]
                                                                                                                  .qty >
                                                                                                          75
                                                                                                        ? 'grnBack'
                                                                                                        : ''
                                                                                                }`}
                                                                                            >
                                                                                                {parseFloat(
                                                                                                    (viewProduct[
                                                                                                        key
                                                                                                    ]
                                                                                                        .masterQty *
                                                                                                        100) /
                                                                                                        viewProduct[
                                                                                                            key
                                                                                                        ]
                                                                                                            .qty,
                                                                                                ).toFixed(
                                                                                                    2,
                                                                                                )}
                                                                                                % of{' '}
                                                                                                {
                                                                                                    viewProduct[
                                                                                                        key
                                                                                                    ]
                                                                                                        .qty
                                                                                                }{' '}
                                                                                                SongShare
                                                                                                Goal
                                                                                                <Tooltip
                                                                                                    placement="top"
                                                                                                    title={`${
                                                                                                        (viewProduct[
                                                                                                            key
                                                                                                        ]
                                                                                                            .masterQty *
                                                                                                            100) /
                                                                                                        viewProduct[
                                                                                                            key
                                                                                                        ]
                                                                                                            .qty
                                                                                                    }
                                                                            % of ${
                                                                                viewProduct[key].qty
                                                                            } song shares has been reserved till now`}
                                                                                                >
                                                                                                    <span className="material-icons ml-2">
                                                                                                        help
                                                                                                    </span>
                                                                                                </Tooltip>
                                                                                            </div>
                                                                                        ) : (
                                                                                            ''
                                                                                        )}
                                                                                    </>
                                                                                ) : (
                                                                                    ''
                                                                                )}
                                                                            </div>
                                                                            <div className="bdBodyContent chartContainer">
                                                                                {viewProduct[key]
                                                                                    .auctiontype ==
                                                                                    'ttw' &&
                                                                                viewProduct[key]
                                                                                    .market_status ===
                                                                                    'open' ? (
                                                                                    <>
                                                                                        {viewProduct[
                                                                                            key
                                                                                        ].sprice ===
                                                                                            viewProduct[
                                                                                                key
                                                                                            ]
                                                                                                .currentbid_increment &&
                                                                                        viewProduct[
                                                                                            key
                                                                                        ]
                                                                                            .masterQty ? (
                                                                                            <>
                                                                                                {(viewProduct[
                                                                                                    key
                                                                                                ]
                                                                                                    .masterQty *
                                                                                                    100) /
                                                                                                    viewProduct[
                                                                                                        key
                                                                                                    ]
                                                                                                        .qty >
                                                                                                    50 &&
                                                                                                (viewProduct[
                                                                                                    key
                                                                                                ]
                                                                                                    .masterQty *
                                                                                                    100) /
                                                                                                    viewProduct[
                                                                                                        key
                                                                                                    ]
                                                                                                        .qty <=
                                                                                                    75 ? (
                                                                                                    <RadialChart
                                                                                                        color="#FAC349"
                                                                                                        chartValue={
                                                                                                            (viewProduct[
                                                                                                                key
                                                                                                            ]
                                                                                                                .masterQty *
                                                                                                                100) /
                                                                                                            viewProduct[
                                                                                                                key
                                                                                                            ]
                                                                                                                .qty
                                                                                                        }
                                                                                                    />
                                                                                                ) : (viewProduct[
                                                                                                      key
                                                                                                  ]
                                                                                                      .masterQty *
                                                                                                      100) /
                                                                                                      viewProduct[
                                                                                                          key
                                                                                                      ]
                                                                                                          .qty >
                                                                                                  75 ? (
                                                                                                    <RadialChart
                                                                                                        color="#0FFDBD"
                                                                                                        chartValue={
                                                                                                            (viewProduct[
                                                                                                                key
                                                                                                            ]
                                                                                                                .masterQty *
                                                                                                                100) /
                                                                                                            viewProduct[
                                                                                                                key
                                                                                                            ]
                                                                                                                .qty
                                                                                                        }
                                                                                                    />
                                                                                                ) : (
                                                                                                    <RadialChart
                                                                                                        color="#FF0000"
                                                                                                        chartValue={
                                                                                                            (viewProduct[
                                                                                                                key
                                                                                                            ]
                                                                                                                .masterQty *
                                                                                                                100) /
                                                                                                            viewProduct[
                                                                                                                key
                                                                                                            ]
                                                                                                                .qty
                                                                                                        }
                                                                                                    />
                                                                                                )}
                                                                                            </>
                                                                                        ) : (
                                                                                            <RadialChart
                                                                                                color={
                                                                                                    '#2F6BAF'
                                                                                                }
                                                                                                chartValue={
                                                                                                    (viewProduct[
                                                                                                        key
                                                                                                    ]
                                                                                                        .masterQty *
                                                                                                        100) /
                                                                                                    viewProduct[
                                                                                                        key
                                                                                                    ]
                                                                                                        .qty
                                                                                                }
                                                                                            />
                                                                                        )}

                                                                                        <div className="txtInforWrpr text-center">
                                                                                            <h4 className="mb-0">
                                                                                                {currencyFormatInt(
                                                                                                    viewProduct[
                                                                                                        key
                                                                                                    ]
                                                                                                        .currentbid_increment,
                                                                                                )}
                                                                                            </h4>
                                                                                            <p className="mb-0">
                                                                                                Current
                                                                                                Bid
                                                                                            </p>
                                                                                            <hr />
                                                                                            <div className="avlblCount d-flex align-items-start">
                                                                                                <div className="text-right">
                                                                                                    <h3 className="active">
                                                                                                        {
                                                                                                            viewProduct[
                                                                                                                key
                                                                                                            ]
                                                                                                                .masterQty
                                                                                                        }
                                                                                                    </h3>
                                                                                                    <p className="active">
                                                                                                        Reserved
                                                                                                        Units
                                                                                                    </p>
                                                                                                </div>
                                                                                                <h3 className="m-0">
                                                                                                    /
                                                                                                </h3>
                                                                                                <div className="text-left">
                                                                                                    <h3>
                                                                                                        {viewProduct[
                                                                                                            key
                                                                                                        ]
                                                                                                            .qty -
                                                                                                            viewProduct[
                                                                                                                key
                                                                                                            ]
                                                                                                                .masterQty}
                                                                                                    </h3>
                                                                                                    <p>
                                                                                                        Available
                                                                                                        Units
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <RadialChart
                                                                                            chartValue={
                                                                                                totalShares >
                                                                                                0
                                                                                                    ? (totalShares *
                                                                                                          100) /
                                                                                                      viewProduct[
                                                                                                          key
                                                                                                      ]
                                                                                                          .qty
                                                                                                    : (allTotalShares *
                                                                                                          100) /
                                                                                                      viewProduct[
                                                                                                          key
                                                                                                      ]
                                                                                                          .qty
                                                                                            }
                                                                                        />

                                                                                        <div className="txtInforWrpr text-center">
                                                                                            <h4 className="mb-0">
                                                                                                {currencyFormatInt(
                                                                                                    viewProduct[
                                                                                                        key
                                                                                                    ]
                                                                                                        .wprice,
                                                                                                )}
                                                                                            </h4>
                                                                                            <p className="mb-0">
                                                                                                Final
                                                                                                Bid
                                                                                            </p>
                                                                                            <hr />
                                                                                            <div className="avlblCount d-flex align-items-start">
                                                                                                <div className="text-right">
                                                                                                    <h3 className="active">
                                                                                                        {totalShares >
                                                                                                        0
                                                                                                            ? totalShares
                                                                                                            : allTotalShares}
                                                                                                    </h3>
                                                                                                    <p className="active">
                                                                                                        Reserved
                                                                                                        Units
                                                                                                    </p>
                                                                                                </div>
                                                                                                <h3 className="m-0">
                                                                                                    /
                                                                                                </h3>
                                                                                                <div className="text-left">
                                                                                                    <h3>
                                                                                                        {viewProduct[
                                                                                                            key
                                                                                                        ]
                                                                                                            .qty -
                                                                                                            viewProduct[
                                                                                                                key
                                                                                                            ]
                                                                                                                .masterQty}
                                                                                                    </h3>
                                                                                                    <p>
                                                                                                        Available
                                                                                                        Units
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                            <div className="tlBdUnitsDtl">
                                                                                <p className="m-0">
                                                                                    Total Units
                                                                                </p>
                                                                                <h3 className="m-0">
                                                                                    <b>
                                                                                        {
                                                                                            viewProduct[
                                                                                                key
                                                                                            ].qty
                                                                                        }
                                                                                    </b>
                                                                                </h3>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                        {user &&
                                                            viewProduct[key].auctiontype ===
                                                                'ttw' && (
                                                                <div className="placeBidWrpr bdHstryTable w-100">
                                                                    {/* <div className="sctnHdr">
                                                                            <h4 className="mb-0">
                                                                                My Bid History
                                                                            </h4>
                                                                            {viewProduct[key]
                                                                                .auctiontype !=
                                                                                'buynow' && (
                                                                                <div
                                                                                    className={`rtWrapper ${
                                                                                        viewProduct[
                                                                                            key
                                                                                        ]
                                                                                            .auctiontype ==
                                                                                        'ttw'
                                                                                            ? 'text-right'
                                                                                            : 'text-left mt-3'
                                                                                    }`}
                                                                                >
                                                                                    {viewProduct[
                                                                                        key
                                                                                    ]
                                                                                        .market_status ===
                                                                                        'open' &&
                                                                                    !viewProduct[
                                                                                        key
                                                                                    ]
                                                                                        .future_active ? (
                                                                                        <>
                                                                                            {viewProduct[
                                                                                                key
                                                                                            ]
                                                                                                .auctiontype ==
                                                                                            'ttw' ? (
                                                                                                <label
                                                                                                    className="vwBdHstyLable cursorPointer mb-0"
                                                                                                    onClick={() =>
                                                                                                        setTTWBidHistoryValue(
                                                                                                            viewProduct[
                                                                                                                key
                                                                                                            ]
                                                                                                                .id,
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <img
                                                                                                        src="/assets/svg/bidhistory_icon.svg"
                                                                                                        className="mr-2"
                                                                                                    />
                                                                                                    View
                                                                                                    Bid
                                                                                                    History
                                                                                                </label>
                                                                                            ) : (
                                                                                                <label
                                                                                                    className="vwBdHstyLable mb-3 cursorPointer"
                                                                                                    onClick={() =>
                                                                                                        setBidHistoryValue(
                                                                                                            viewProduct[
                                                                                                                key
                                                                                                            ]
                                                                                                                .id,
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <img
                                                                                                        src="/assets/svg/bidhistory_icon.svg"
                                                                                                        className="mr-2"
                                                                                                    />
                                                                                                    View
                                                                                                    Bid
                                                                                                    History
                                                                                                </label>
                                                                                            )}
                                                                                        </>
                                                                                    ) : (
                                                                                        ''
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                        </div> */}
                                                                    <table className="table mt-1">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col">
                                                                                    Shares
                                                                                </th>
                                                                                <th scope="col">
                                                                                    Price
                                                                                </th>
                                                                                <th scope="col">
                                                                                    Outbid
                                                                                </th>
                                                                                <th scope="col">
                                                                                    Remaining
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {isAuthenticated &&
                                                                            myBids.length ? (
                                                                                myBids.map(
                                                                                    (
                                                                                        data,
                                                                                        index,
                                                                                    ) => (
                                                                                        <>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    {
                                                                                                        data.init_proposed_amount
                                                                                                    }
                                                                                                </td>
                                                                                                <td>
                                                                                                    {currencyFormatInt(
                                                                                                        data.currentbid_increment,
                                                                                                    )}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {data.init_proposed_amount !=
                                                                                                    data.proposed_amount
                                                                                                        ? `- ${
                                                                                                              data.init_proposed_amount -
                                                                                                              data.proposed_amount
                                                                                                          } @ ( ${currencyFormatInt(
                                                                                                              data.currentbid_increment,
                                                                                                          )}
                                                                        )`
                                                                                                        : ''}
                                                                                                </td>

                                                                                                <td>
                                                                                                    {
                                                                                                        data.proposed_amount
                                                                                                    }
                                                                                                </td>
                                                                                            </tr>
                                                                                        </>
                                                                                    ),
                                                                                )
                                                                            ) : (
                                                                                <tr>
                                                                                    <td
                                                                                        colSpan="5"
                                                                                        style={{
                                                                                            opacity: 0.5,
                                                                                            textAlign:
                                                                                                'center',
                                                                                        }}
                                                                                    >
                                                                                        Your Bid
                                                                                        History Will
                                                                                        Be Shown
                                                                                        Here
                                                                                    </td>
                                                                                </tr>
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            )}
                                                    </>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                    ) : null}
                                </div>
                            ) : (
                                <div
                                    className={`offrngWrapper ${
                                        viewProduct[key].auctiontype !== 'buynow'
                                            ? ''
                                            : 'ofrngBuyCntnr'
                                    } pdtTopContainer ${
                                        viewProduct[key].private_sale === 0
                                            ? 'customContainer'
                                            : 'pt-5'
                                    }`}
                                >
                                    {/* {viewProduct[key].auctiontype === 'buynow' &&
                                    viewProduct[key].market_status !== 'closed' ? (
                                        <div className="mnTpCntnr prdtClosedWrapper customContainer">
                                            <div className="cntntContainer">
                                                <img
                                                    className="productImage"
                                                    src={
                                                        global.images_url + viewProduct[key].avatar
                                                    }
                                                />
                                                <h4>{viewProduct[key].title}</h4>
                                                <p>
                                                    All SongShares have been purchased. Please look
                                                    at our other offerings at this time
                                                </p>
                                                <PrimaryButton
                                                    onClick={() =>
                                                        handleRedirectInternal(history, 'search')
                                                    }
                                                >
                                                    <img
                                                        src="/assets/svg/backarrow.svg"
                                                        className="mr-2"
                                                    />
                                                    Back to All Investments
                                                </PrimaryButton>
                                            </div>
                                        </div>
                                    ) : ( */}
                                    <>
                                        {isAuthenticated && (
                                            <>
                                                {viewProduct[key].bidtopstatus === 'outbid' && (
                                                    <div className="pvBidStatus">
                                                        <h4 className="outbid">
                                                            {'YOU HAVE BEEN OUTBID'}.
                                                        </h4>
                                                    </div>
                                                )}
                                                {viewProduct[key].bidtopstatus === 'winner' && (
                                                    <div className="pvBidStatus">
                                                        <h4 className="winning">
                                                            {'YOU ARE WINNING THIS ITEM'}.
                                                        </h4>
                                                    </div>
                                                )}
                                                {viewProduct[key].bidtopstatus === 'won' && (
                                                    <div className="pvBidStatus">
                                                        <h4 className="won">{'CONGRATULATIONS'}</h4>
                                                    </div>
                                                )}
                                                {viewProduct[key].bidtopstatus === 'lost' &&
                                                    viewProduct[key].wprice >=
                                                        viewProduct[key].rprice && (
                                                        <div className="pvBidStatus">
                                                            <h4 className="lost">
                                                                UNFORTUNATELY, YOU LOST THIS ITEM
                                                            </h4>
                                                        </div>
                                                    )}
                                                {viewProduct[key].bidtopstatus === 'lost' &&
                                                    viewProduct[key].wprice <
                                                        viewProduct[key].rprice && (
                                                        <div className="pvBidStatus">
                                                            <h4 className="lost text-center">
                                                                {viewProduct[key].auctiontype !==
                                                                    'live' ||
                                                                (viewProduct[key].bidhistory &&
                                                                    viewProduct[key].bidhistory
                                                                        .length > 0 &&
                                                                    viewProduct[key].bidhistory[0]
                                                                        .user_id !== user.id)
                                                                    ? `Thanks for bidding! Unfortunately, you did not win this auction`
                                                                    : `You made the highest bid, but it did not meet the reserve price.  There's still an opportunity and we will contact you directly!`}
                                                            </h4>
                                                        </div>
                                                    )}
                                            </>
                                        )}
                                        <div
                                            className={`mnTpCntnr${
                                                viewProduct[key].auctiontype !== 'buynow'
                                                    ? ' mnTpForAuc'
                                                    : ' mnTpforOfrntBuy'
                                            }`}
                                        >
                                            <div
                                                className={`lftContainer ${
                                                    viewProduct[key].market_status === 'open' &&
                                                    viewProduct[key].auctiontype !== 'buynow'
                                                        ? 'nrmblLftBiddingWrpr'
                                                        : ''
                                                }`}
                                            >
                                                {/* <Button
                                                    className="backButton"
                                                    onClick={() =>
                                                        handleRedirectInternal(history, 'search')
                                                    }
                                                >
                                                    {' '}
                                                    <img
                                                        src="/assets/svg/backarrow.svg"
                                                        className="mr-2"
                                                    />{' '}
                                                    <b>Back to All Investments</b>
                                                </Button> */}
                                                <div
                                                    className={`pvTimerCnt ${
                                                        viewProduct[key].auctiontype === 'buynow'
                                                            ? 'placeBidWrpr'
                                                            : ''
                                                    } d-flex align-items-stretch`}
                                                >
                                                    {viewProduct[key].auctiontype === 'buynow' ? (
                                                        <></>
                                                    ) : viewProduct[key].market_status ===
                                                      'open' ? (
                                                        <div className="pvTimerBox nrmlBdTimer extndRuleCntnr">
                                                            {viewProduct[key].private_sale ===
                                                                0 && (
                                                                <div className="hlpTltpCntnr">
                                                                    <CustomPopover
                                                                        label={<HelpIcon />}
                                                                        content={`If any bid is made during the last ${allConfigurationVariables.ext_min_limit} minutes, the timer will extend by ${allConfigurationVariables.bidding_extended} minutes to give everyone a chance to bid.`}
                                                                    />
                                                                </div>
                                                            )}

                                                            <Timer
                                                                date_added={
                                                                    viewProduct[key].date_added
                                                                }
                                                                date_closed={
                                                                    viewProduct[key].date_closed
                                                                }
                                                                withText={1}
                                                                endText="Ends in: "
                                                                startText="Starts in: "
                                                            ></Timer>
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                                {/* {viewProduct[key].auctiontype === 'buynow' && (
                                                    <p
                                                        className="hwToByTxt cursorDecoy"
                                                        onClick={() => setHowToBuy(true)}
                                                    >
                                                        <span className="material-icons mr-2">
                                                            play_circle
                                                        </span>
                                                        <span className="ulTxtWrp">
                                                            How to Buy Song Shares on SongVest
                                                            tutorial
                                                        </span>
                                                    </p>
                                                )} */}

                                                {viewProduct[key] &&
                                                !viewProduct[key].isPublicVisible &&
                                                !viewProduct[key].isPrivateVisible ? (
                                                    <div className="mt-2 d-flex align-items-center timerOverWrpr">
                                                        <div className="ofrndStartsTmr">
                                                            <div className="pvTimerCnt placeBidWrpr d-flex align-items-stretch">
                                                                {/* <p className="mb-0">
                                                                    Public Offering Starts in
                                                                </p> */}
                                                                <span className="tmrTxtCnt pvTimerBox publicOfferingTimer">
                                                                    <div className="tmrTxt">
                                                                        Public Offering Starts in
                                                                    </div>
                                                                    <Timer
                                                                        date_closed={
                                                                            viewProduct[key]
                                                                                .public_start_date
                                                                        }
                                                                        withText={0}
                                                                        endText="Public Offering Ends in: "
                                                                        startText="Public Offering Starts in: "
                                                                        callBack={() =>
                                                                            getAllSearchProducts(
                                                                                search,
                                                                            )
                                                                        }
                                                                    ></Timer>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null}
                                                <div className="d-flex align-items-start">
                                                    <div className="pdtImg">
                                                        <img
                                                            src={
                                                                global.images_url +
                                                                viewProduct[key].avatar
                                                            }
                                                        />
                                                    </div>
                                                    <div className="pdtTxtInfo pl-4 text-left d-flex align-items-end justify-content-between w-100">
                                                        <div className="w-100">
                                                            {/* {viewProduct[key]?.itemPropsArray?.length ? ( */}
                                                            {/* <div className="pcgArtist">
                                                                <img
                                                                    src={
                                                                        viewProduct[key]
                                                                            ?.itemPropsArray[0]
                                                                            ?.artist_image
                                                                    }
                                                                    alt="Artist image"
                                                                />
                                                                <span>
                                                                    {
                                                                        viewProduct[key]
                                                                            ?.itemPropsArray[0]
                                                                            ?.artist
                                                                    }
                                                                </span>
                                                            </div> */}
                                                            {/* ) : null} */}
                                                            <div className="pdtNmInfoWrpr d-flex align-items-center my-2">
                                                                <h2 className="pdtName">
                                                                    {viewProduct[key].title}
                                                                </h2>
                                                            </div>
                                                            {viewProduct[key].auctiontype ==
                                                            'buynow' ? (
                                                                <>
                                                                    {viewProduct[key]
                                                                        .about_short_description &&
                                                                    viewProduct[key]
                                                                        .about_short_description
                                                                        ?.length !== 0 ? (
                                                                        <div className={`pvAbt`}>
                                                                            {viewProduct[key]
                                                                                .about_short_description &&
                                                                            viewProduct[key]
                                                                                .about_short_description
                                                                                ?.length !== 0 ? (
                                                                                <>
                                                                                    {(viewProduct[
                                                                                        key
                                                                                    ]
                                                                                        .about_short_description &&
                                                                                        viewProduct[
                                                                                            key
                                                                                        ]
                                                                                            .about_short_description
                                                                                            ?.length <
                                                                                            150) ||
                                                                                    showDescription ? (
                                                                                        <>
                                                                                            <div
                                                                                                className="pdtAbtCntnr"
                                                                                                dangerouslySetInnerHTML={{
                                                                                                    __html:
                                                                                                        viewProduct[
                                                                                                            key
                                                                                                        ]
                                                                                                            .about_short_description,
                                                                                                }}
                                                                                            ></div>
                                                                                            {viewProduct[
                                                                                                key
                                                                                            ]
                                                                                                .about_short_description
                                                                                                ?.length >
                                                                                                150 && (
                                                                                                <p
                                                                                                    className="showDscrption"
                                                                                                    onClick={() =>
                                                                                                        setShowDescription(
                                                                                                            false,
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    Hide
                                                                                                    full
                                                                                                    description
                                                                                                </p>
                                                                                            )}
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <div
                                                                                                className="pdtAbtCntnr"
                                                                                                dangerouslySetInnerHTML={{
                                                                                                    __html:
                                                                                                        viewProduct[
                                                                                                            key
                                                                                                        ]
                                                                                                            .about_short_description &&
                                                                                                        viewProduct[
                                                                                                            key
                                                                                                        ].about_short_description.slice(
                                                                                                            0,
                                                                                                            150,
                                                                                                        ) +
                                                                                                            '....',
                                                                                                }}
                                                                                            ></div>
                                                                                            <p
                                                                                                className="showDscrption"
                                                                                                onClick={() =>
                                                                                                    setShowDescription(
                                                                                                        true,
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                See
                                                                                                full
                                                                                                description
                                                                                            </p>
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            ) : (
                                                                                <small>
                                                                                    No Data Found
                                                                                </small>
                                                                            )}
                                                                        </div>
                                                                    ) : null}
                                                                </>
                                                            ) : viewProduct[key].auctiontype ==
                                                              'live' ? (
                                                                <>
                                                                    {viewProduct[key].short_desc &&
                                                                    viewProduct[key].short_desc
                                                                        ?.length !== 0 ? (
                                                                        <div className={`pvAbt`}>
                                                                            {viewProduct[key]
                                                                                .short_desc &&
                                                                            viewProduct[key]
                                                                                .short_desc
                                                                                ?.length !== 0 ? (
                                                                                <>
                                                                                    {(viewProduct[
                                                                                        key
                                                                                    ].short_desc &&
                                                                                        viewProduct[
                                                                                            key
                                                                                        ].short_desc
                                                                                            ?.length <
                                                                                            150) ||
                                                                                    showDescription ? (
                                                                                        <>
                                                                                            <div
                                                                                                className="pdtAbtCntnr"
                                                                                                dangerouslySetInnerHTML={{
                                                                                                    __html:
                                                                                                        viewProduct[
                                                                                                            key
                                                                                                        ]
                                                                                                            .short_desc,
                                                                                                }}
                                                                                            ></div>
                                                                                            {viewProduct[
                                                                                                key
                                                                                            ]
                                                                                                .short_desc
                                                                                                ?.length >
                                                                                                150 && (
                                                                                                <p
                                                                                                    className="showDscrption"
                                                                                                    onClick={() =>
                                                                                                        setShowDescription(
                                                                                                            false,
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    Hide
                                                                                                    full
                                                                                                    description
                                                                                                </p>
                                                                                            )}
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <div
                                                                                                className="pdtAbtCntnr"
                                                                                                dangerouslySetInnerHTML={{
                                                                                                    __html:
                                                                                                        viewProduct[
                                                                                                            key
                                                                                                        ]
                                                                                                            .short_desc &&
                                                                                                        viewProduct[
                                                                                                            key
                                                                                                        ].short_desc.slice(
                                                                                                            0,
                                                                                                            150,
                                                                                                        ) +
                                                                                                            '....',
                                                                                                }}
                                                                                            ></div>
                                                                                            <p
                                                                                                className="showDscrption"
                                                                                                onClick={() =>
                                                                                                    setShowDescription(
                                                                                                        true,
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                See
                                                                                                full
                                                                                                description
                                                                                            </p>
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            ) : (
                                                                                <small>
                                                                                    No Data Found
                                                                                </small>
                                                                            )}
                                                                        </div>
                                                                    ) : null}
                                                                </>
                                                            ) : (
                                                                ''
                                                            )}

                                                            {viewProduct[key].auctiontype ===
                                                                'buynow' &&
                                                                (viewProduct[key].annual_royalties /
                                                                    viewProduct[key].qty >
                                                                0 ? (
                                                                    <h6 className="pvRoyaltiesPerYear">
                                                                        Royalties Paid Per Unit
                                                                        (last 12 months):{' '}
                                                                        <span>
                                                                            {currencyFormatTwo(
                                                                                viewProduct[key]
                                                                                    .annual_royalties /
                                                                                    viewProduct[key]
                                                                                        .qty,
                                                                            )}
                                                                        </span>
                                                                    </h6>
                                                                ) : (
                                                                    ''
                                                                ))}

                                                            {isAuthenticated &&
                                                                !card &&
                                                                !bank &&
                                                                user &&
                                                                (viewProduct[key].auctiontype ==
                                                                'live' ? (
                                                                    <Link to="/paymentinfo">
                                                                        To bid you need to add a
                                                                        credit card or bank details.
                                                                        This is only for
                                                                        verification not purchase.
                                                                    </Link>
                                                                ) : (
                                                                    <Link to="/paymentinfo">
                                                                        Click here to add bank/ card
                                                                        details to bid or buy
                                                                    </Link>
                                                                ))}
                                                        </div>
                                                    </div>
                                                </div>

                                                {viewProduct[key].auctiontype === 'buynow' &&
                                                viewProduct[key].market_status === 'closed' ? (
                                                    <div className="clsdPrchsDtls">
                                                        <p>
                                                            All SongShares have been purchased.
                                                            Please look at our other offerings at
                                                            this time
                                                        </p>
                                                        <PrimaryButton
                                                            onClick={() =>
                                                                handleRedirectInternal(
                                                                    history,
                                                                    'search',
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src="/assets/svg/backarrow.svg"
                                                                className="mr-2"
                                                            />
                                                            Back to All Investments
                                                        </PrimaryButton>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                                <>
                                                    {viewProduct[key].auctiontype !== 'buynow' ? (
                                                        <>
                                                            {viewProduct[key].qty -
                                                                viewProduct[key].sold ==
                                                            '0' ? (
                                                                <></>
                                                            ) : (
                                                                <>
                                                                    {viewProduct[key]
                                                                        .isPublicVisible && (
                                                                        <div className="pvTimerBox pvtMrBynw">
                                                                            {viewProduct[key]
                                                                                .sold &&
                                                                            (viewProduct[key].sold *
                                                                                100) /
                                                                                viewProduct[key]
                                                                                    .qty >
                                                                                75 ? (
                                                                                <h5 className="m-0">
                                                                                    <Timer
                                                                                        date_closed={
                                                                                            viewProduct[
                                                                                                key
                                                                                            ]
                                                                                                .date_closed
                                                                                        }
                                                                                        withText={1}
                                                                                        endText="Ends in: "
                                                                                        startText="Starts in: "
                                                                                    ></Timer>
                                                                                </h5>
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {viewProduct[key] &&
                                                        viewProduct[key].isPrivateVisible &&
                                                        !viewProduct[key].isPublicVisible && (
                                                            <div>
                                                                {viewProduct[key].market_status ===
                                                                'open' ? (
                                                                    <div className="pvTimerBox">
                                                                        <Timer
                                                                            date_added={
                                                                                viewProduct[key]
                                                                                    .date_added
                                                                            }
                                                                            date_closed={
                                                                                viewProduct[key]
                                                                                    .public_start_date
                                                                            }
                                                                            withText={1}
                                                                            endText="VIP Sale Ends in: "
                                                                            startText="Starts in: "
                                                                        ></Timer>
                                                                    </div>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </div>
                                                        )}
                                                </>
                                            </div>
                                            {console.log(viewProduct[key], 'viewPPP')}
                                            <div
                                                className={`rtContainer bdngDtlWrapper ${
                                                    viewProduct[key].market_status === 'open' &&
                                                    viewProduct[key].auctiontype !== 'buynow'
                                                        ? 'nrmblBiddingWrpr'
                                                        : ''
                                                }`}
                                            >
                                                {viewProduct[key].qty - viewProduct[key].sold !=
                                                    '0' &&
                                                viewProduct[key].market_status === 'open' &&
                                                viewProduct[key].auctiontype === 'buynow' ? (
                                                    <p className="availableUnits">
                                                        {((viewProduct[key]?.qty -
                                                            (viewProduct[key]?.sold ||
                                                                viewProduct[key]?.privateSold)) /
                                                            viewProduct[key]?.qty) *
                                                            100 <=
                                                        25
                                                            ? `${parseFloat(
                                                                  ((viewProduct[key]?.qty -
                                                                      (viewProduct[key]?.sold ||
                                                                          viewProduct[key]
                                                                              ?.privateSold)) /
                                                                      viewProduct[key]?.qty) *
                                                                      100,
                                                              ).toFixed(1)}
                                                        % units left`
                                                            : ''}
                                                    </p>
                                                ) : (
                                                    ''
                                                )}

                                                <div
                                                    className={`saleInner ${
                                                        viewProduct[key].market_status
                                                    } ${
                                                        viewProduct[key] &&
                                                        !viewProduct[key].isPublicVisible &&
                                                        !viewProduct[key].isPrivateVisible
                                                            ? 'publicFuture'
                                                            : ''
                                                    }`}
                                                >
                                                    <div className="pvRt">
                                                        {viewProduct[key].auctiontype == 'buynow' &&
                                                        (viewProduct[key]?.privateSold > 0 ||
                                                            viewProduct[key]?.purchasedQty > 0) ? (
                                                            <div className="pvMyUnits">
                                                                <h4>
                                                                    {viewProduct[key].privateSold
                                                                        ? viewProduct[key]
                                                                              .privateSold
                                                                        : viewProduct[key]
                                                                              .purchasedQty
                                                                        ? viewProduct[key]
                                                                              .purchasedQty
                                                                        : '0'}
                                                                </h4>
                                                                <p> My Units</p>
                                                            </div>
                                                        ) : null}

                                                        <div className="pvUnitPrice">
                                                            <h3>
                                                                {viewProduct[key].private_sale ===
                                                                1 ? (
                                                                    <b>
                                                                        {currencyFormatInt(
                                                                            viewProduct[key].sprice,
                                                                        )}
                                                                        {viewProduct[key].rprice &&
                                                                        viewProduct[key].rprice >
                                                                            0 ? (
                                                                            <>
                                                                                {'-'}
                                                                                {currencyFormatInt(
                                                                                    viewProduct[key]
                                                                                        .rprice,
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    </b>
                                                                ) : viewProduct[key].freeShare >
                                                                      0 &&
                                                                  viewProduct[key].freeShareExist >
                                                                      0 ? (
                                                                    <b>
                                                                        {
                                                                            viewProduct[key]
                                                                                .pendingQuantity
                                                                        }
                                                                    </b>
                                                                ) : (
                                                                    <b>
                                                                        {currencyFormatInt(
                                                                            viewProduct[key]
                                                                                .auctiontype ===
                                                                                'live'
                                                                                ? viewProduct[key]
                                                                                      .wprice
                                                                                : viewProduct[key]
                                                                                      .bprice,
                                                                        )}
                                                                    </b>
                                                                )}
                                                            </h3>
                                                            {viewProduct[key].auctiontype !==
                                                            'buynow' ? (
                                                                <p>Price</p>
                                                            ) : (
                                                                <>
                                                                    {viewProduct[key].freeShare >
                                                                        0 &&
                                                                    viewProduct[key]
                                                                        .freeShareExist > 0 ? (
                                                                        <p>Free Share Available</p>
                                                                    ) : (
                                                                        <p>Per Unit Price</p>
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div
                                                        className={`pvPriceAct ${
                                                            viewProduct[key].auctiontype !==
                                                            'buynow'
                                                                ? 'position-relative'
                                                                : ''
                                                        }`}
                                                    >
                                                        <div
                                                            className={`songDetails text-left d-flex align-items-center ${
                                                                viewProduct[key].auctiontype ===
                                                                'buynow'
                                                                    ? 'ofrngWrpRp'
                                                                    : ''
                                                            }`}
                                                        >
                                                            {viewProduct[key].auctiontype ===
                                                                'buynow' &&
                                                                viewProduct[key].freeShare == 0 && (
                                                                    <div className="grdCntnr">
                                                                        <h3>
                                                                            {currencyFormatTwo(
                                                                                viewProduct[key]
                                                                                    .annual_royalties /
                                                                                    viewProduct[key]
                                                                                        .qty,
                                                                            )}
                                                                        </h3>
                                                                        <p>
                                                                            Royalties Paid Per{' '}
                                                                            <br /> Unit (last 12
                                                                            months)
                                                                        </p>
                                                                    </div>
                                                                )}
                                                        </div>
                                                        {!isAuthenticated &&
                                                            viewProduct[0].market_status !=
                                                                'closed' &&
                                                            viewProduct[key].private_sale === 0 && (
                                                                <>
                                                                    <div
                                                                        className={
                                                                            `ofrngNrmlBd bidInput d-flex align-items-start ` +
                                                                            viewProduct[0]
                                                                                .auctiontype
                                                                        }
                                                                    >
                                                                        <div className="biddingCnt buyNnwContainer beforeLogin d-flex justify-content-start align-items-start">
                                                                            {
                                                                                viewProduct[0]
                                                                                    .auctiontype ===
                                                                                'live'
                                                                                    ? Object.values(
                                                                                          mapData(
                                                                                              liveBidAmount,
                                                                                          ),
                                                                                      )
                                                                                    : ''
                                                                                // Object.values(
                                                                                //       mapData(
                                                                                //           bidAmount,
                                                                                //       ),
                                                                                //   )
                                                                            }
                                                                            {viewProduct[0]
                                                                                .auctiontype ===
                                                                            'buynow' ? (
                                                                                // <PrimaryButton
                                                                                //     label="Login to buy"
                                                                                //     btnSize="small"
                                                                                //     id="buynow_offering"
                                                                                //     onClick={() =>
                                                                                //         handleRedirectInternal(
                                                                                //             history,
                                                                                //             'login?redirectTo=' +
                                                                                //                 window
                                                                                //                     .location
                                                                                //                     .pathname,
                                                                                //         )
                                                                                //     }
                                                                                // />
                                                                                <Buynow
                                                                                    data={
                                                                                        viewProduct[
                                                                                            key
                                                                                        ]
                                                                                    }
                                                                                    editQuantity={
                                                                                        true
                                                                                    }
                                                                                    isOnlyBuynow={
                                                                                        true
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                <PrimaryButton
                                                                                    label="Login to bid"
                                                                                    btnSize="small"
                                                                                    id="loginto_buynow_offering"
                                                                                    onClick={() =>
                                                                                        handleRedirectInternal(
                                                                                            history,
                                                                                            'login?redirectTo=' +
                                                                                                window
                                                                                                    .location
                                                                                                    .pathname,
                                                                                        )
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}

                                                        {viewProduct[key].private_sale === 1 &&
                                                            viewProduct[key].auctiontype ===
                                                                'live' && (
                                                                <>
                                                                    <div className="ofrngNrmlBd mt-3">
                                                                        <div className="biddingCnt buyNnwContainer beforeLogin d-flex justify-content-start align-items-start">
                                                                            {isAuthenticated &&
                                                                                (!card &&
                                                                                !bank ? null : (
                                                                                    <PrimaryButton
                                                                                        label={
                                                                                            'Make an Offer'
                                                                                        }
                                                                                        id="makeanoffer_button"
                                                                                        btnSize="small"
                                                                                        disabled={
                                                                                            sentBuyAllNowRequest
                                                                                        }
                                                                                        onClick={() =>
                                                                                            showBuyAllShareModal(
                                                                                                true,
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                ))}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        {viewProduct[key].future_active === 0 &&
                                                            isAuthenticated &&
                                                            viewProduct[key].private_sale === 0 && (
                                                                <>
                                                                    <div className="ofrngNrmlBd bidInput">
                                                                        {viewProduct[key]
                                                                            .auctiontype ===
                                                                        'live' ? (
                                                                            <>
                                                                                {viewProduct[key]
                                                                                    .market_status ===
                                                                                    'open' && (
                                                                                    <>
                                                                                        <div className="d-flex align-items-center nrmlAuctBid">
                                                                                            {viewProduct[
                                                                                                key
                                                                                            ]
                                                                                                .auction &&
                                                                                                (card ||
                                                                                                    bank) && (
                                                                                                    <Bidding
                                                                                                        data={
                                                                                                            viewProduct[
                                                                                                                key
                                                                                                            ]
                                                                                                        }
                                                                                                        type="hard"
                                                                                                    />
                                                                                                )}
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        ) : viewProduct[key]
                                                                              .auctiontype ===
                                                                          'buynow' ? (
                                                                            <>
                                                                                {viewProduct[key]
                                                                                    .qty -
                                                                                    viewProduct[key]
                                                                                        .sold ==
                                                                                '0' ? (
                                                                                    <>
                                                                                        <div className="sldOutWrpr">
                                                                                            Sold Out
                                                                                        </div>

                                                                                        {/* <Button
//                                                                                             className="pvViewInvoice"
//                                                                                             onClick={() =>
//                                                                                                 handleRedirectInternal(
//                                                                                                     history,
//                                                                                                     `invoice/${viewProduct[key].common_invoice}`,
//                                                                                                 )
//                                                                                             }
//                                                                                         >
//                                                                                             View
//                                                                                             Invoice
//                                                                                         </Button> */}
                                                                                        <div className="ofrnCrcl">
                                                                                            <a
                                                                                                href={
                                                                                                    viewProduct[
                                                                                                        key
                                                                                                    ]
                                                                                                        .viewOfferingLink
                                                                                                        ? viewProduct[
                                                                                                              key
                                                                                                          ]
                                                                                                              .viewOfferingLink
                                                                                                        : 'https://www.songvest.com/offering'
                                                                                                }
                                                                                                target="blank"
                                                                                            >
                                                                                                View
                                                                                                Offering
                                                                                                Circular
                                                                                            </a>

                                                                                            {/* <FavoriteCheckbox
                                                                                            watchlisted={
                                                                                                viewProduct[
                                                                                                    key
                                                                                                ]
                                                                                                    .watchlistid
                                                                                            }
                                                                                            project_id={
                                                                                                viewProduct[
                                                                                                    key
                                                                                                ].id
                                                                                            }
                                                                                            trigger={() =>
                                                                                                favTrigger()
                                                                                            }
                                                                                            addLabel={
                                                                                                'Add to Watchlist'
                                                                                            }
                                                                                            removeLabel={
                                                                                                'Remove Watchlist'
                                                                                            }
                                                                                        /> */}
                                                                                        </div>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        {viewProduct[
                                                                                            key
                                                                                        ] &&
                                                                                            (viewProduct[
                                                                                                key
                                                                                            ]
                                                                                                .isPublicVisible ||
                                                                                                viewProduct[
                                                                                                    key
                                                                                                ]
                                                                                                    .isPrivateVisible) && (
                                                                                                <>
                                                                                                    {(bank ||
                                                                                                        card) && (
                                                                                                        <>
                                                                                                            {viewProduct[
                                                                                                                key
                                                                                                            ]
                                                                                                                .freeShare >
                                                                                                            0 ? (
                                                                                                                <>
                                                                                                                    {viewProduct[
                                                                                                                        key
                                                                                                                    ]
                                                                                                                        .freeShareExist >
                                                                                                                    0 ? (
                                                                                                                        <>
                                                                                                                            <BuynowFree
                                                                                                                                data={
                                                                                                                                    viewProduct[
                                                                                                                                        key
                                                                                                                                    ]
                                                                                                                                }
                                                                                                                                editQuantity={
                                                                                                                                    true
                                                                                                                                }
                                                                                                                                isOnlyBuynow={
                                                                                                                                    true
                                                                                                                                }
                                                                                                                            />
                                                                                                                        </>
                                                                                                                    ) : (
                                                                                                                        <div className="getShrsFree">
                                                                                                                            You
                                                                                                                            can
                                                                                                                            get
                                                                                                                            these
                                                                                                                            SongShares
                                                                                                                            for
                                                                                                                            free.
                                                                                                                            <br />
                                                                                                                            <a href="https://songvest.com">
                                                                                                                                Click
                                                                                                                                here
                                                                                                                            </a>
                                                                                                                            to
                                                                                                                            find
                                                                                                                            out
                                                                                                                            how!
                                                                                                                        </div>
                                                                                                                    )}
                                                                                                                </>
                                                                                                            ) : (
                                                                                                                <>
                                                                                                                    <Buynow
                                                                                                                        data={
                                                                                                                            viewProduct[
                                                                                                                                key
                                                                                                                            ]
                                                                                                                        }
                                                                                                                        editQuantity={
                                                                                                                            true
                                                                                                                        }
                                                                                                                        isOnlyBuynow={
                                                                                                                            true
                                                                                                                        }
                                                                                                                    />
                                                                                                                </>
                                                                                                            )}
                                                                                                        </>
                                                                                                    )}
                                                                                                    {/* {!bank &&
                                                                                                    !card &&
                                                                                                    user && (
                                                                                                        <Link to="/paymentinfo">
                                                                                                            Click
                                                                                                            here
                                                                                                            to
                                                                                                            add
                                                                                                            bank
                                                                                                            /
                                                                                                            card
                                                                                                            details
                                                                                                            to
                                                                                                            buy
                                                                                                        </Link>
                                                                                                    )} */}
                                                                                                    {!viewProduct[
                                                                                                        key
                                                                                                    ]
                                                                                                        .isPublicVisible &&
                                                                                                    viewProduct[
                                                                                                        key
                                                                                                    ]
                                                                                                        .isPrivateVisible ? (
                                                                                                        viewProduct[
                                                                                                            key
                                                                                                        ]
                                                                                                            ?.privateQty -
                                                                                                            viewProduct[
                                                                                                                key
                                                                                                            ]
                                                                                                                ?.privateSold <=
                                                                                                        0 ? (
                                                                                                            <p className="text-danger purchSngShrTxt">
                                                                                                                You
                                                                                                                have
                                                                                                                purchased
                                                                                                                all
                                                                                                                of
                                                                                                                your
                                                                                                                reserved
                                                                                                                SongShares.
                                                                                                                If
                                                                                                                you
                                                                                                                want
                                                                                                                to
                                                                                                                purchase
                                                                                                                more,
                                                                                                                you
                                                                                                                can
                                                                                                                try
                                                                                                                again
                                                                                                                during
                                                                                                                the
                                                                                                                Public
                                                                                                                Offering.
                                                                                                            </p>
                                                                                                        ) : (
                                                                                                            <p className="text-danger purchSngShrTxt">
                                                                                                                You
                                                                                                                have
                                                                                                                purchased{' '}
                                                                                                                {
                                                                                                                    viewProduct[
                                                                                                                        key
                                                                                                                    ]
                                                                                                                        ?.privateSold
                                                                                                                }{' '}
                                                                                                                SongShares.
                                                                                                                You
                                                                                                                can
                                                                                                                still
                                                                                                                purchase
                                                                                                                up
                                                                                                                to{' '}
                                                                                                                {viewProduct[
                                                                                                                    key
                                                                                                                ]
                                                                                                                    ?.privateQty -
                                                                                                                    viewProduct[
                                                                                                                        key
                                                                                                                    ]
                                                                                                                        ?.privateSold}{' '}
                                                                                                                units
                                                                                                                during
                                                                                                                the
                                                                                                                VIP
                                                                                                                Sale.
                                                                                                            </p>
                                                                                                        )
                                                                                                    ) : (
                                                                                                        ''
                                                                                                    )}

                                                                                                    <div className="ofrnCrcl">
                                                                                                        <a
                                                                                                            href={
                                                                                                                viewProduct[
                                                                                                                    key
                                                                                                                ]
                                                                                                                    .viewOfferingLink
                                                                                                                    ? viewProduct[
                                                                                                                          key
                                                                                                                      ]
                                                                                                                          .viewOfferingLink
                                                                                                                    : 'https://www.songvest.com/offering'
                                                                                                            }
                                                                                                            target="blank"
                                                                                                        >
                                                                                                            View
                                                                                                            Offering
                                                                                                            Circular
                                                                                                        </a>

                                                                                                        {/* <FavoriteCheckbox
                                                                                                        watchlisted={
                                                                                                            viewProduct[
                                                                                                                key
                                                                                                            ]
                                                                                                                .watchlistid
                                                                                                        }
                                                                                                        project_id={
                                                                                                            viewProduct[
                                                                                                                key
                                                                                                            ]
                                                                                                                .id
                                                                                                        }
                                                                                                        trigger={() =>
                                                                                                            favTrigger()
                                                                                                        }
                                                                                                        addLabel={
                                                                                                            'Add to Watchlist'
                                                                                                        }
                                                                                                        removeLabel={
                                                                                                            'Remove Watchlist'
                                                                                                        }
                                                                                                    /> */}
                                                                                                    </div>
                                                                                                </>
                                                                                            )}
                                                                                        {viewProduct[
                                                                                            key
                                                                                        ]
                                                                                            .freeShare >
                                                                                            0 &&
                                                                                            viewProduct[
                                                                                                key
                                                                                            ]
                                                                                                .freeShareExist >
                                                                                                0 && (
                                                                                                <div className="frQtyWrp">
                                                                                                    <p>
                                                                                                        You
                                                                                                        have
                                                                                                        claimed
                                                                                                        <span className="qtyValueFree">
                                                                                                            {viewProduct[
                                                                                                                key
                                                                                                            ]
                                                                                                                .freeQuantity -
                                                                                                                viewProduct[
                                                                                                                    key
                                                                                                                ]
                                                                                                                    .pendingQuantity}
                                                                                                        </span>
                                                                                                        of
                                                                                                        these
                                                                                                        SongShares.
                                                                                                        You
                                                                                                        can
                                                                                                        still
                                                                                                        claim
                                                                                                        upto
                                                                                                        <span className="qtyValueFree">
                                                                                                            {viewProduct[
                                                                                                                key
                                                                                                            ]
                                                                                                                .qty -
                                                                                                                viewProduct[
                                                                                                                    key
                                                                                                                ]
                                                                                                                    ?.freeQuantity}
                                                                                                        </span>
                                                                                                        during
                                                                                                        the
                                                                                                        sale.
                                                                                                    </p>
                                                                                                </div>
                                                                                            )}
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    </div>
                                                                    {/* <div
                                                                    className={`mkNrmlPrxyBtn d-flex justify-content-between align-items-center ${
                                                                        !card || !bank
                                                                            ? 'noCardBank'
                                                                            : ''
                                                                    }`}
                                                                >
                                                                    {viewProduct[key]
                                                                        .auctiontype === 'live' && (
                                                                        <>
                                                                            <label
                                                                                className="vwBdHstyLable cursorPointer mb-0 mr-2 d-flex align-items-center"
                                                                                onClick={() =>
                                                                                    setBidHistoryValue(
                                                                                        viewProduct[
                                                                                            key
                                                                                        ].id,
                                                                                    )
                                                                                }
                                                                                id="viewbidhistory_text"
                                                                            >
                                                                                <img
                                                                                    src="/assets/svg/bidhistory_icon.svg"
                                                                                    className="mr-2"
                                                                                />
                                                                                View Bid History
                                                                            </label>
                                                                            {viewProduct[key]
                                                                                .market_status ===
                                                                                'open' &&
                                                                                (card || bank) && (
                                                                                    <PrimaryButton
                                                                                        id="makeproxybid_button"
                                                                                        label="Make Proxy Bid"
                                                                                        onClick={() =>
                                                                                            setNormalProxy(
                                                                                                true,
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                )}
                                                                        </>
                                                                    )}
                                                                </div> */}
                                                                    <div>
                                                                        {viewProduct[key]
                                                                            .auctiontype ===
                                                                            'live' && (
                                                                            <>
                                                                                {/* <FavoriteCheckbox
                                                                                        watchlisted={
                                                                                            viewProduct[
                                                                                                key
                                                                                            ]
                                                                                                .watchlistid
                                                                                        }
                                                                                        project_id={
                                                                                            viewProduct[
                                                                                                key
                                                                                            ].id
                                                                                        }
                                                                                        trigger={() =>
                                                                                            favTrigger()
                                                                                        }
                                                                                        addLabel={
                                                                                            'Add to Watchlist'
                                                                                        }
                                                                                        removeLabel={
                                                                                            'Remove Watchlist'
                                                                                        }
                                                                                    /> */}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </>
                                                            )}

                                                        {/* {viewProduct[key].auctiontype === 'live' ? (
                                                        <div className="offerWatchlist">
                                                            <a
                                                                href={
                                                                    viewProduct[key]
                                                                        .viewOfferingLink
                                                                        ? viewProduct[key]
                                                                              .viewOfferingLink
                                                                        : 'https://www.songvest.com/offering'
                                                                }
                                                                target="blank"
                                                            >
                                                                View Offering Circular
                                                            </a>

                                                            <FavoriteCheckbox
                                                                watchlisted={
                                                                    viewProduct[key].watchlistid
                                                                }
                                                                project_id={viewProduct[key].id}
                                                                trigger={() => favTrigger()}
                                                                addLabel={'Add to Watchlist'}
                                                                removeLabel={'Remove Watchlist'}
                                                            />
                                                        </div>
                                                    ) : null} */}

                                                        {/* <h6 className="offerCatalog">
                                                        Make an Offer on the whole Catalog{' '}
                                                        <a href="#">HERE</a>
                                                    </h6> */}

                                                        {isAuthenticated ? (
                                                            <>
                                                                {viewProduct[key].bid_or_not > 0 &&
                                                                    viewProduct[key]
                                                                        .market_status === 'open' &&
                                                                    viewProduct[key]
                                                                        .private_sale === 0 &&
                                                                    (viewProduct[key]
                                                                        .auctiontype === 'live' &&
                                                                    viewProduct[key].wprice <
                                                                        viewProduct[key].rprice ? (
                                                                        <p className="rsrvNtMet">
                                                                            Reserve Price Not Met
                                                                        </p>
                                                                    ) : viewProduct[key]
                                                                          .auctiontype === 'live' &&
                                                                      viewProduct[key].wprice >=
                                                                          viewProduct[key]
                                                                              .rprice ? (
                                                                        <p className="rsrvMet">
                                                                            Reserve Price Met
                                                                        </p>
                                                                    ) : (
                                                                        ''
                                                                    ))}
                                                            </>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    {/* )} */}
                                </div>
                            )}

                            <div className="customContainer">
                                {event && (
                                    <div className="adCaledarDiv d-flex align-items-center justify-content-end">
                                        <label className="m-0">
                                            <small>Add to Calendar:</small>
                                        </label>
                                        <a href={google(event)} target="_blank" rel="noreferrer">
                                            <Tooltip title="Google Calendar">
                                                <img src="/assets/svg/google_calendar.svg" />
                                            </Tooltip>
                                        </a>
                                        <a href={outlook(event)} target="_blank" rel="noreferrer">
                                            <Tooltip title="Outlook">
                                                <img src="/assets/svg/outlook.svg" />
                                            </Tooltip>
                                        </a>
                                        <a href={office365(event)} target="_blank" rel="noreferrer">
                                            <Tooltip title="Office 365">
                                                <img src="/assets/svg/office.svg" />
                                            </Tooltip>
                                        </a>
                                        <a href={yahoo(event)} target="_blank" rel="noreferrer">
                                            <Tooltip title="Yahoo">
                                                <img src="/assets/svg/yahoo.svg" />
                                            </Tooltip>
                                        </a>
                                        <a href={ics(event)} target="_blank" rel="noreferrer">
                                            <Tooltip title="iCalendar">
                                                <img src="/assets/images/iCalendar.png" />
                                            </Tooltip>
                                        </a>
                                    </div>
                                )}
                                {console.log(
                                    viewProduct[key].auctiontype == 'buynow' ||
                                        viewProduct[key].auctiontype == 'ttw',
                                    viewProduct[key].auctiontype,
                                    'auctiontypeCheck',
                                )}
                                {console.log(viewProduct[key].private_sale, 'private_sale')}
                                {isAuthenticated ? (
                                    <>
                                        {' '}
                                        {viewProduct[key].auctiontype == 'buynow' ||
                                        viewProduct[key].auctiontype == 'ttw' ||
                                        viewProduct[key].private_sale == 1 ? null : (
                                            <div className="miscBidding">
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography className="">
                                                            {' '}
                                                            <img src="/assets/svg/proxyicon.svg" />
                                                            MAKE A PROXY BID
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        {viewProduct[key].future_active === 0 &&
                                                            isAuthenticated &&
                                                            viewProduct[key].private_sale === 0 && (
                                                                <div
                                                                    className={`mkNrmlPrxyBtn d-flex justify-content-between align-items-center ${
                                                                        !card || !bank
                                                                            ? 'noCardBank'
                                                                            : ''
                                                                    }`}
                                                                >
                                                                    {viewProduct[key]
                                                                        .auctiontype === 'live' &&
                                                                        viewProduct[key]
                                                                            .market_status ===
                                                                            'open' &&
                                                                        (card || bank) && (
                                                                            <>
                                                                                {(card || bank) && (
                                                                                    <Bidding
                                                                                        data={
                                                                                            viewProduct[
                                                                                                key
                                                                                            ]
                                                                                        }
                                                                                        type="proxy"
                                                                                    />
                                                                                )}
                                                                                {!card &&
                                                                                    !bank &&
                                                                                    user && (
                                                                                        <Link to="/paymentinfo">
                                                                                            Click
                                                                                            here to
                                                                                            add bank
                                                                                            / card
                                                                                            details
                                                                                            to bid
                                                                                            or buy
                                                                                        </Link>
                                                                                    )}
                                                                                {/* <PrimaryButton
                                                                        id="makeproxybid_button"
                                                                        label="Make Proxy Bid"
                                                                        onClick={() =>
                                                                            setNormalProxy(true)
                                                                        }
                                                                    /> */}
                                                                            </>
                                                                        )}
                                                                </div>
                                                            )}
                                                    </AccordionDetails>
                                                </Accordion>
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel2a-content"
                                                        id="panel2a-header"
                                                        // onClick={() => setBidHistoryValue(viewProduct[key].id)}
                                                    >
                                                        <Typography className="">
                                                            <img src="/assets/svg/bidhistory_icon.svg" />
                                                            MY BID HISTORY
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        {user &&
                                                            (viewProduct[key].auctiontype ===
                                                            'ttw' ? (
                                                                <div className="placeBidWrpr bdHstryTable">
                                                                    <div className="sctnHdr">
                                                                        <h4 className="mb-0">
                                                                            My Bid History
                                                                        </h4>
                                                                        {viewProduct[key]
                                                                            .auctiontype !=
                                                                            'buynow' && (
                                                                            <div
                                                                                className={`rtWrapper ${
                                                                                    viewProduct[key]
                                                                                        .auctiontype ==
                                                                                    'ttw'
                                                                                        ? 'text-right'
                                                                                        : 'text-left mt-3'
                                                                                }`}
                                                                            >
                                                                                {viewProduct[key]
                                                                                    .market_status ===
                                                                                    'open' &&
                                                                                !viewProduct[key]
                                                                                    .future_active ? (
                                                                                    <>
                                                                                        {viewProduct[
                                                                                            key
                                                                                        ]
                                                                                            .auctiontype ==
                                                                                        'ttw' ? (
                                                                                            <label
                                                                                                className="vwBdHstyLable cursorPointer mb-0"
                                                                                                onClick={() =>
                                                                                                    setTTWBidHistoryValue(
                                                                                                        viewProduct[
                                                                                                            key
                                                                                                        ]
                                                                                                            .id,
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <img
                                                                                                    src="/assets/svg/bidhistory_icon.svg"
                                                                                                    className="mr-2"
                                                                                                />
                                                                                                View
                                                                                                Bid
                                                                                                History
                                                                                            </label>
                                                                                        ) : (
                                                                                            <label
                                                                                                className="vwBdHstyLable mb-3 cursorPointer"
                                                                                                onClick={() =>
                                                                                                    setBidHistoryValue(
                                                                                                        viewProduct[
                                                                                                            key
                                                                                                        ]
                                                                                                            .id,
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <img
                                                                                                    src="/assets/svg/bidhistory_icon.svg"
                                                                                                    className="mr-2"
                                                                                                />
                                                                                                View
                                                                                                Bid
                                                                                                History
                                                                                            </label>
                                                                                        )}
                                                                                    </>
                                                                                ) : (
                                                                                    ''
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <table className="table mt-1">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col">
                                                                                    Units
                                                                                </th>
                                                                                <th scope="col">
                                                                                    Price
                                                                                </th>
                                                                                <th scope="col">
                                                                                    Outbid
                                                                                </th>
                                                                                <th scope="col">
                                                                                    Remaining
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {isAuthenticated &&
                                                                            myBids.length ? (
                                                                                myBids.map(
                                                                                    (
                                                                                        data,
                                                                                        index,
                                                                                    ) => (
                                                                                        <>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    {
                                                                                                        data.init_proposed_amount
                                                                                                    }
                                                                                                </td>
                                                                                                <td>
                                                                                                    {currencyFormatInt(
                                                                                                        data.currentbid_increment,
                                                                                                    )}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {data.init_proposed_amount !=
                                                                                                    data.proposed_amount
                                                                                                        ? `- ${
                                                                                                              data.init_proposed_amount -
                                                                                                              data.proposed_amount
                                                                                                          } @ ( ${currencyFormatInt(
                                                                                                              data.currentbid_increment,
                                                                                                          )}
                                                                        )`
                                                                                                        : ''}
                                                                                                </td>

                                                                                                <td>
                                                                                                    {
                                                                                                        data.proposed_amount
                                                                                                    }
                                                                                                </td>
                                                                                            </tr>
                                                                                        </>
                                                                                    ),
                                                                                )
                                                                            ) : (
                                                                                <tr>
                                                                                    <td
                                                                                        colSpan="5"
                                                                                        style={{
                                                                                            opacity: 0.5,
                                                                                            textAlign:
                                                                                                'center',
                                                                                        }}
                                                                                    >
                                                                                        Your Bid
                                                                                        History Will
                                                                                        Be Shown
                                                                                        Here
                                                                                    </td>
                                                                                </tr>
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            ) : (
                                                                <BidHistory
                                                                    modal={false}
                                                                    id={viewProduct[key].id}
                                                                    refresh={bidHistoryChange}
                                                                />
                                                            ))}
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                        )}
                                    </>
                                ) : null}
                                {viewProduct[key].auctiontype === 'ttw' ||
                                viewProduct[key].auctiontype === 'buynow' ? null : (
                                    <>
                                        {viewProduct[key].itemPropsArray.length !== 0 && (
                                            <>
                                                <div className="pdtDtlTtle mt-5">
                                                    <h4>Track Details</h4>
                                                </div>
                                                <div className="trackListWrapper py-4">
                                                    <div className="trackGrid">
                                                        <TrackCard
                                                            items={viewProduct[key].itemPropsArray}
                                                            handleClose={(index) => {
                                                                setTrackIndex(index)
                                                                setMoreInfo(true)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                                {/* {viewProduct[key].auctiontype === 'ttw' ? null : (
                                    <div
                                        className={`miscAccess ${
                                            viewProduct[key].auctiontype === 'buynow'
                                                ? 'maBuynow'
                                                : ''
                                        }`}
                                    >
                                        <h4>What you Get for One Unit</h4>
                                        <div className="miscAccessInner">
                                            <div className="miscAccessItem">
                                                <img
                                                    src="https://songvestapi.ecommerce.auction/uploads/product/file_upload-1698833337634_364651.jpeg"
                                                    alt="Item"
                                                />
                                                <div>
                                                    <h6>Certified Authentic</h6>
                                                    <p>
                                                        A Certificate of Authenticity in your name
                                                        signed by the Artist to show your ShongShare
                                                        ownership.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )} */}
                                <div className="pdtDtlTtle noBorder" ref={myRef}>
                                    <h4></h4>
                                </div>
                                {viewProduct[key].auctiontype !== 'ttw' &&
                                viewProduct[key].auctiontype !== 'buynow' ? (
                                    <>
                                        <div className="pdtDescriptionCont">
                                            <div className="mt-5">
                                                <div className="fldVlu d-flex align-items-center">
                                                    <label className="m-0 mr-2">
                                                        <b>Type of Rights:</b>
                                                    </label>
                                                    <p className="m-0">
                                                        {allAssetType &&
                                                            allAssetType
                                                                .sort((a, b) => {
                                                                    let x = a.description
                                                                            .toString()
                                                                            .toUpperCase(),
                                                                        y = b.description
                                                                            .toString()
                                                                            .toUpperCase()
                                                                    return x == y
                                                                        ? 0
                                                                        : x > y
                                                                        ? 1
                                                                        : -1
                                                                })
                                                                .map(
                                                                    (asset, index) =>
                                                                        viewProduct[key]
                                                                            .asset_type &&
                                                                        viewProduct[key].asset_type
                                                                            .split(',')
                                                                            .indexOf(
                                                                                asset.id.toString(),
                                                                            ) > -1 && (
                                                                            <>
                                                                                {asset.description}
                                                                                <span>{', '}</span>
                                                                            </>
                                                                        ),
                                                                )}
                                                    </p>
                                                </div>
                                                <div className="fldVlu d-flex align-items-center">
                                                    <label className="m-0 mr-2">
                                                        <b>Type of Income:</b>
                                                    </label>
                                                    <p className="m-0">
                                                        {allIncomeType &&
                                                            allIncomeType
                                                                .sort((a, b) => {
                                                                    let x = a.description
                                                                            .toString()
                                                                            .toUpperCase(),
                                                                        y = b.description
                                                                            .toString()
                                                                            .toUpperCase()
                                                                    return x == y
                                                                        ? 0
                                                                        : x > y
                                                                        ? 1
                                                                        : -1
                                                                })
                                                                .map(
                                                                    (asset, index) =>
                                                                        viewProduct[key]
                                                                            .income_type &&
                                                                        viewProduct[key].income_type
                                                                            .split(',')
                                                                            .indexOf(
                                                                                asset.id.toString(),
                                                                            ) > -1 && (
                                                                            <>
                                                                                {asset.description}
                                                                                <span>{', '}</span>
                                                                            </>
                                                                        ),
                                                                )}
                                                    </p>
                                                </div>

                                                <div className="fldVlu d-flex align-items-center">
                                                    <label className="m-0 mr-2">
                                                        <b>Paid By:</b>
                                                    </label>
                                                    <p className="m-0">
                                                        {allPaidBy &&
                                                            allPaidBy
                                                                .sort((a, b) => {
                                                                    let x = a.description
                                                                            .toString()
                                                                            .toUpperCase(),
                                                                        y = b.description
                                                                            .toString()
                                                                            .toUpperCase()
                                                                    return x == y
                                                                        ? 0
                                                                        : x > y
                                                                        ? 1
                                                                        : -1
                                                                })
                                                                .map(
                                                                    (asset, index) =>
                                                                        viewProduct[key].paid_by &&
                                                                        viewProduct[key].paid_by
                                                                            .split(',')
                                                                            .indexOf(
                                                                                asset.id.toString(),
                                                                            ) > -1 && (
                                                                            <>
                                                                                {asset.description}
                                                                                <span>
                                                                                    <span>
                                                                                        {', '}
                                                                                    </span>
                                                                                </span>
                                                                            </>
                                                                        ),
                                                                )}
                                                    </p>
                                                </div>
                                                <div className="fldVlu d-flex align-items-center">
                                                    <label className="m-0 mr-2">
                                                        <b>Term (Life or Yrs):</b>
                                                    </label>
                                                    <p className="m-0">
                                                        {allTerm &&
                                                            allTerm.map(
                                                                (asset, index) =>
                                                                    viewProduct[key].term &&
                                                                    viewProduct[key].term
                                                                        .split(',')
                                                                        .indexOf(
                                                                            asset.id.toString(),
                                                                        ) > -1 && (
                                                                        <>
                                                                            {asset.description}
                                                                            <span>
                                                                                <span>{', '}</span>
                                                                            </span>
                                                                        </>
                                                                    ),
                                                            )}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                                {/* {viewProduct[key].auctiontype === 'buynow' &&
                                viewProduct[key].qty - viewProduct[key].sold == '0' ? (
                                    <>
                                        {certificateData.length
                                            ? certificateData.map((data) =>
                                                  data.buynow_ownership_certificate ? (
                                                      <div className="getCertificate">
                                                          <img
                                                              src="/assets/images/celebrate.png"
                                                              alt="Celebrate icon"
                                                          />
                                                          <div className="gcInfo">
                                                              <h3>
                                                                  Congratulations on purchasing{' '}
                                                                  <span>
                                                                      &quot;{viewProduct[key].title}
                                                                      &quot;
                                                                  </span>
                                                              </h3>
                                                              <h3>
                                                                  You can now download a copy of
                                                                  your Digital Certificate.
                                                              </h3>
                                                          </div>
                                                          <PrimaryButton
                                                              onClick={() =>
                                                                  handlePrint(
                                                                      `${global.site_url}/uploads/certificate/${data.buynow_ownership_certificate}`,
                                                                  )
                                                              }
                                                          >
                                                              <span className="material-icons-outlined">
                                                                  file_download
                                                              </span>
                                                              Download Certificate
                                                          </PrimaryButton>
                                                      </div>
                                                  ) : null,
                                              )
                                            : null}

                                        <div className="customizedPlaque">
                                            <div>
                                                <h3>Get your customized TLC record plaque.</h3>
                                                <PrimaryButton
                                                    label="Buy Now"
                                                    btnSize="small"
                                                    onClick={() =>
                                                        handleBuy(viewProduct[key]?.viewSoldoutLink)
                                                    }
                                                />
                                            </div>
                                            <img
                                                className="cpBanner"
                                                src={`${process.env.REACT_APP_DOMAIN}/uploads/config/${viewProduct[key].soldout_banner}`}
                                                alt="Product banner"
                                            />
                                        </div> 
                                    </>
                                ) : null} */}

                                <div
                                    className="ck-content"
                                    dangerouslySetInnerHTML={{
                                        __html: viewProduct[key].desc_proc.replace(
                                            /<figure>&nbsp;<\/figure>/g,
                                            '',
                                        ),
                                    }}
                                ></div>
                                {viewProduct[key].auctiontype === 'ttw' ||
                                viewProduct[key].auctiontype === 'buynow' ? (
                                    <>
                                        {viewProduct[key].itemPropsArray.length !== 0 && (
                                            <>
                                                <div className="pdtDtlTtle mt-5">
                                                    <h4>Track Details</h4>
                                                </div>
                                                <div className="trackListWrapper py-4">
                                                    <div className="trackGrid">
                                                        <TrackCard
                                                            items={viewProduct[key].itemPropsArray}
                                                            handleClose={(index) => {
                                                                setTrackIndex(index)
                                                                setMoreInfo(true)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </>
                                ) : null}
                                {/* <div className="conclusionWrapper">
                                    <h6>Conclusion</h6>
                                    <p>
                                        The singer, rapper, songwriter, and producer was once
                                        engrossed in a variety of visual arts, but when graffiti was
                                        declared worthy of a felony vandalism charge, he opted to
                                        devote his creative energy to music.
                                    </p>
                                    <Button>
                                        <img src="/assets/svg/rightarrow.svg" /> Detailed data here
                                    </Button>
                                </div> */}
                                {viewProduct[key].auctiontype == 'ttw' && (
                                    <div className="mt-5 mb-5">
                                        <p className="dsclmrTxtPdt">
                                            ROYALTYTRADERS LLC (DBA SONGVEST) IS TESTING THE WATERS
                                            UNDER TIER 2 OF REGULATION A. NO MONEY OR OTHER
                                            CONSIDERATION IS BEING SOLICITED, AND IF SENT IN
                                            RESPONSE, WILL NOT BE ACCEPTED. NO OFFER TO BUY THE
                                            SECURITIES CAN BE ACCEPTED AND NO PART OF THE PURCHASE
                                            PRICE CAN BE RECEIVED UNTIL THE OFFERING STATEMENT FILED
                                            BY THE COMPANY WITH THE SEC HAS BEEN QUALIFIED BY THE
                                            SEC. ANY SUCH OFFER MAY BE WITHDRAWN OR REVOKED, WITHOUT
                                            OBLIGATION OR COMMITMENT OF ANY KIND, AT ANY TIME BEFORE
                                            NOTICE OF ACCEPTANCE GIVEN AFTER THE DATE OF
                                            QUALIFICATION. AN INDICATION OF INTEREST INVOLVES NO
                                            OBLIGATION OR COMMITMENT OF ANY KIND. AN OFFERING
                                            STATEMENT REGARDING THIS OFFERING HAS BEEN FILED WITH
                                            THE SEC.{' '}
                                            <a
                                                href="https://www.songvest.com/offering"
                                                target="blank"
                                            >
                                                https://www.songvest.com/offering
                                            </a>
                                        </p>
                                    </div>
                                )}
                            </div>
                            {viewProduct[key].auctiontype === 'buynow' &&
                            viewProduct[key].qty - viewProduct[key].sold == '0' ? (
                                <>
                                    {/* <div className="row investDetails customContainer">
                                        <div className="pdtDtlTtle col-12">
                                            <h4 className="m-0">Investment Details</h4>
                                        </div>
                                    </div> */}
                                    {/* <div className="cntnrWrpr row customContainer">
                                        <div className="col-md-6 col-12">
                                            <div className="grphContainer ">
                                                <div className="tbHdr d-flex align-items-start justify-content-between">
                                                    <p className="active grphHdr">
                                                        Total Royalties
                                                    </p>
                                                    {Object.values(mapData(dateRangeDroptwo))}
                                                </div>
                                                {royaltyChartData?.length ? (
                                                    ''
                                                ) : (
                                                    <p className="placeHldrTxt">
                                                        No Royalty Payments Received Yet
                                                    </p>
                                                )}
                                                <Line
                                                    data={royaltyChart}
                                                    type="line"
                                                    options={{
                                                        tooltips: {
                                                            callbacks: {
                                                                label: function (
                                                                    tooltipItems,
                                                                    data,
                                                                ) {
                                                                    return (
                                                                        '$' +
                                                                        tooltipItems.yLabel.toString()
                                                                    )
                                                                },
                                                            },
                                                        },
                                                        scales: {
                                                            yAxes: [
                                                                {
                                                                    ticks: {
                                                                        beginAtZero: true,
                                                                        callback: function (
                                                                            value,
                                                                            index,
                                                                            values,
                                                                        ) {
                                                                            if (
                                                                                royaltyChartData?.length >
                                                                                0
                                                                            ) {
                                                                                return (
                                                                                    '$' +
                                                                                    Math.round(
                                                                                        value,
                                                                                    )
                                                                                )
                                                                            } else {
                                                                                return '$' + value
                                                                            }
                                                                        },
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                        legend: {
                                                            display: false,
                                                        },
                                                        layout: {
                                                            padding: 20,
                                                        },
                                                        elements: {
                                                            line: {
                                                                width: 2,
                                                                borderColor: '#306CAE',
                                                            },
                                                            point: {
                                                                radius: 5,
                                                            },
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="row dshbrdTransTable">
                                        <div className="col-md-12 myOwnShares">
                                            <div className="dashTableWrpr">
                                                <div className="tblTabHdr d-flex align-items-center justify-content-between">
                                                    <label className="mnLbl">
                                                        Recent Transactions
                                                    </label>
                                                    <IconButton
                                                        className="collapseButton"
                                                        onClick={() => setTableFour(!tableFour)}
                                                    >
                                                        {tableFour ? (
                                                            <span className="material-icons">
                                                                remove
                                                            </span>
                                                        ) : (
                                                            <span className="material-icons">
                                                                add
                                                            </span>
                                                        )}
                                                    </IconButton>
                                                </div>
                                                {tableFour && (
                                                    <>
                                                        <div className="table-responsive dpTable">
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Transaction Date</th>
                                                                        <th>Transaction Type</th>
                                                                        <th>Offering Name</th>
                                                                        <th>Invoice ID</th>
                                                                        <th>Payment Method</th>
                                                                        <th>Amount</th>
                                                                        <th>Status</th>
                                                                        <th className="d-flex justify-content-center">
                                                                            View Invoice
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {recentTransactions &&
                                                                        recentTransactions.length >
                                                                            0 &&
                                                                        recentTransactions.map(
                                                                            (data, key) => (
                                                                                <tr>
                                                                                    <td>
                                                                                        {dateTimeFormatFunction(
                                                                                            data.created_at,
                                                                                        )}
                                                                                    </td>
                                                                                    <td>
                                                                                        {data.dwollaTransact ==
                                                                                        1 ? (
                                                                                            <>
                                                                                                {data.type &&
                                                                                                    data.type ==
                                                                                                        'Bank To Wallet' && (
                                                                                                        <>
                                                                                                            {
                                                                                                                'DWOLLA WALLET - ADD FUNDS'
                                                                                                            }
                                                                                                        </>
                                                                                                    )}
                                                                                                {data.type &&
                                                                                                    data.type ==
                                                                                                        'Wallet To Bank' && (
                                                                                                        <>
                                                                                                            {
                                                                                                                'DWOLLA WALLET - WITHDRAW FUNDS'
                                                                                                            }
                                                                                                        </>
                                                                                                    )}
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                {!data.transaction_type ||
                                                                                                (data.transaction_type &&
                                                                                                    data.transaction_type ==
                                                                                                        'invoice') ? (
                                                                                                    <>
                                                                                                        {!data.type && (
                                                                                                            <>
                                                                                                                {
                                                                                                                    'PURCHASE'
                                                                                                                }
                                                                                                            </>
                                                                                                        )}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        {
                                                                                                            data.transaction_type
                                                                                                        }
                                                                                                    </>
                                                                                                )}
                                                                                            </>
                                                                                        )}
                                                                                    </td>
                                                                                    <td>
                                                                                        {data.title
                                                                                            ? `${data.title}`
                                                                                            : '-'}
                                                                                    </td>
                                                                                    <td>
                                                                                        {data.buynow_common_invoice
                                                                                            ? `# ${data.buynow_common_invoice}`
                                                                                            : '-'}
                                                                                    </td>
                                                                                    <td>
                                                                                        {data.payment_method ===
                                                                                        'cash' ? (
                                                                                            <>
                                                                                                {
                                                                                                    'Cash'
                                                                                                }
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                {data.payment_method ==
                                                                                                'CREDITCARD'
                                                                                                    ? 'CREDIT CARD'
                                                                                                    : data.payment_method}
                                                                                            </>
                                                                                        )}
                                                                                    </td>
                                                                                    <td>
                                                                                        {data.transaction_status ? (
                                                                                            <>
                                                                                                {data.transaction_status ==
                                                                                                    'Refund Pending' ||
                                                                                                data.transaction_status ==
                                                                                                    'Refund Complete'
                                                                                                    ? '-'
                                                                                                    : ''}
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                {data.transaction_type &&
                                                                                                data.transaction_type ==
                                                                                                    'Refund' &&
                                                                                                data.payment_status !=
                                                                                                    'Declined'
                                                                                                    ? '-'
                                                                                                    : ''}
                                                                                            </>
                                                                                        )}
                                                                                        {currencyFormat(
                                                                                            data.amount,
                                                                                        )}
                                                                                    </td>
                                                                                    <td>
                                                                                        {data.transaction_status
                                                                                            ? data.transaction_status
                                                                                            : data.payment_status}
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="d-flex justify-content-center">
                                                                                            {!data.transaction_type ||
                                                                                            (data.transaction_type &&
                                                                                                data.transaction_type ==
                                                                                                    'invoice') ||
                                                                                            data.transaction_type ==
                                                                                                'Refund' ? (
                                                                                                <>
                                                                                                    <Button
                                                                                                        onClick={() =>
                                                                                                            window.open(
                                                                                                                `/invoice/${data.buynow_common_invoice}`,
                                                                                                                '_blank',
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <span className="material-icons">
                                                                                                            open_in_new
                                                                                                        </span>
                                                                                                    </Button>
                                                                                                </>
                                                                                            ) : (
                                                                                                '-'
                                                                                            )}
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            ),
                                                                        )}

                                                                    {recentTransactions &&
                                                                    recentTransactions.length <=
                                                                        0 ? (
                                                                        <tr>
                                                                            <td colSpan="8">
                                                                                <p className="d-flex justify-content-center mb-0">
                                                                                    No Recent
                                                                                    Transactions
                                                                                    Found.
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </tbody>
                                                                <tfoot className="tfootTab">
                                                                    <tr>
                                                                        <td colSpan={10}>
                                                                            {recentTransactions &&
                                                                                recentTransactions.length >
                                                                                    4 && <></>}
                                                                        </td>
                                                                    </tr>
                                                                </tfoot>
                                                            </table>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div> */}
                                </>
                            ) : null}
                            <Popup
                                open={pxBidStatus}
                                size="md"
                                handleClose={() => setPxBidStatus(false)}
                                modaltitle="Proxy Bid Status"
                            >
                                <div className="pdtDtlContainer mystsPopup">
                                    <div className="stsMtrWrapper w-100 text-left">
                                        <div className="shrStsWrpr d-flex align-items-center w-100">
                                            <label className="m-0">Availability</label>
                                            <div className="nwRpWrpr">
                                                {currencyFormat(
                                                    viewProduct[key].currentbid_increment,
                                                )}
                                            </div>
                                            <div className="stsBxs d-flex w-100">
                                                {viewProduct[key].masterQty ? (
                                                    <Tooltip title={viewProduct[key].masterQty}>
                                                        <div
                                                            className={`avlBox`}
                                                            style={{
                                                                background: `rgba(1, 125, 193,${
                                                                    1 - (0 + '.' + 0)
                                                                })`,
                                                                color: 'white',
                                                                width:
                                                                    (viewProduct[key].masterQty *
                                                                        100) /
                                                                        viewProduct[key].qty +
                                                                    '%',
                                                            }}
                                                        >
                                                            {viewProduct[key].masterQty}
                                                        </div>
                                                    </Tooltip>
                                                ) : null}
                                                {viewProduct[key].qty -
                                                    viewProduct[key].masterQty !=
                                                    0 && (
                                                    <>
                                                        <Tooltip
                                                            title={
                                                                viewProduct[key].qty -
                                                                viewProduct[key].masterQty
                                                            }
                                                        >
                                                            <div
                                                                className={`avlBox`}
                                                                style={{
                                                                    background: '#2e2e2e',
                                                                    width:
                                                                        ((viewProduct[key].qty -
                                                                            viewProduct[key]
                                                                                .masterQty) *
                                                                            100) /
                                                                            viewProduct[key].qty +
                                                                        '%',
                                                                }}
                                                            >
                                                                {viewProduct[key].qty -
                                                                    viewProduct[key].masterQty}
                                                            </div>
                                                        </Tooltip>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        {futureProxy.lenght != 0 && (
                                            <div className="shrStsWrpr d-flex align-items-center w-100 mt-4">
                                                <label className="m-0">&nbsp;</label>
                                                <div className="nwRpWrpr">&nbsp;</div>
                                                <div className="prtyWrpper">
                                                    <h3>UNIT PRIORITY</h3>
                                                    <div className="d-flex align-items-center">
                                                        <img src="/assets/images/low.png" />
                                                        <p className="text-center m-0 mx-3">
                                                            This bar chart shows your active bids
                                                            and proxy bids as it relates to
                                                            priority. As bids come in at higher unit
                                                            prices the lower priority bids are
                                                            knocked out first and higher priority
                                                            bids are the last to be lost.
                                                        </p>
                                                        <img src="/assets/images/high.png" />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {user &&
                                            futureProxy.users.indexOf(user.id) > -1 &&
                                            Object.keys(futureProxy.bids).map((proxyBid, index) => (
                                                <div className="shrStsWrpr d-flex align-items-center w-100 mt-4">
                                                    <label className="m-0">My Units</label>
                                                    <div className="nwRpWrpr">
                                                        {currencyFormat(proxyBid)}
                                                    </div>
                                                    <div className="stsBxs justify-content-end d-flex w-100">
                                                        <div
                                                            className="dmyStstusBox"
                                                            style={{
                                                                width: '100%',
                                                                background: 'white',
                                                            }}
                                                        ></div>
                                                        {futureProxy.bids[proxyBid]
                                                            .slice(0)
                                                            .reverse()
                                                            .map((data, index) => (
                                                                <div
                                                                    key={index}
                                                                    className={`statusBox ${
                                                                        data.user_id == user.id
                                                                            ? 'crntUser'
                                                                            : 'ntCrntUsr'
                                                                    }`}
                                                                    style={{
                                                                        minWidth:
                                                                            (data.maxqty /
                                                                                viewProduct[key]
                                                                                    .qty) *
                                                                                100 +
                                                                            '%',
                                                                        background:
                                                                            data.user_id !==
                                                                                user.id &&
                                                                            'transparent',
                                                                    }}
                                                                >
                                                                    <CustomPopover
                                                                        label={
                                                                            data.user_id == user.id
                                                                                ? data.maxqty +
                                                                                  ' units @ ' +
                                                                                  currencyFormatInt(
                                                                                      data.maxamount,
                                                                                  )
                                                                                : ''
                                                                        }
                                                                        content={
                                                                            data.maxqty +
                                                                            ' units @ ' +
                                                                            currencyFormatInt(
                                                                                data.maxamount,
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            ))}
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </Popup>
                        </>
                    ) : null}
                    <Popup
                        open={buyAllShareModal}
                        size="sm"
                        handleClose={() => closeBuyAllSharesModal()}
                        modaltitle="Offer Details"
                    >
                        {/* <div className="row">{Object.values(mapData(buyAllshareInput))}</div>  */}
                        {/*<p>
                            Thanks for your interest in purchasing all units of this offering. Please fill out details below.
                            <b>
                                {currencyFormatTwo(
                                    viewProduct[key]?.qty * viewProduct[key]?.sprice,
                                )}
                            </b>{' '}
                            ({viewProduct[key]?.qty} x {viewProduct[key]?.sprice})?
                        </p>*/}
                        <p>
                            {viewProduct[key]?.private_sale === 1 ? (
                                <>
                                    Thank you for your interest in purchasing this catalog. Please
                                    let us know what price you want to offer or any additional
                                    comments in the box below
                                </>
                            ) : (
                                <>
                                    Thanks for your interest in purchasing all units of this
                                    offering. Please submit the price you are willing to pay to own
                                    this asset, and our team will be in touch soon.
                                </>
                            )}
                        </p>

                        {viewProduct[key]?.private_sale === 0 && (
                            <>
                                <div className="row mb-2">
                                    <div className="col-sm-12">{viewProduct[key]?.title} Offer</div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-5 pr-0" style={{ lineHeight: '58px' }}>
                                        <b>{viewProduct[key]?.qty}</b> units at ${' '}
                                    </div>
                                    <div className="col-sm-7">
                                        {Object.values(mapData(buyAllshareInput))}{' '}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <b>
                                            $
                                            {numberFormatCommaSeparate(
                                                currencyFormatTwoNoDollar(
                                                    viewProduct[key]?.qty *
                                                        buyAllShareFormik.values.amount,
                                                ),
                                            )}
                                        </b>{' '}
                                        total price offered.
                                    </div>
                                </div>
                            </>
                        )}

                        {viewProduct[key]?.private_sale === 1 && (
                            <>
                                <div className="row">
                                    {Object.values(mapData(buyAllshareInput))}{' '}
                                </div>
                            </>
                        )}

                        <div className="d-flex align-items-center justify-content-end mt-3">
                            <Button
                                className="clsBtn mr-4"
                                onClick={() => closeBuyAllSharesModal()}
                            >
                                Cancel
                            </Button>
                            <PrimaryButton
                                label={
                                    viewProduct[key]?.private_sale === 0
                                        ? 'Submit Offer'
                                        : 'Make An Offer'
                                }
                                onClick={() => triggerNotifyBuyAllNowShareAction()}
                            />
                        </div>
                    </Popup>
                    <Popup
                        open={mkProxy}
                        size="sm"
                        handleClose={() => setMkProxy(false)}
                        modaltitle="Proxy Bid"
                    >
                        <div className="prxyPopup">
                            {(card || bank) && <TTWBidding data={viewProduct[key]} type="proxy" />}
                            {!card && !bank && user && (
                                <Link to="/paymentinfo">
                                    Click here to add bank/ card details to bid or buy
                                </Link>
                            )}
                        </div>
                    </Popup>
                    <Popup
                        open={normalProxy}
                        size="sm"
                        handleClose={() => setNormalProxy(false)}
                        modaltitle="Proxy Bid"
                    >
                        <div className="prxyPopup">
                            {(card || bank) && <Bidding data={viewProduct[key]} type="proxy" />}
                            {!card && !bank && user && (
                                <Link to="/paymentinfo">
                                    Click here to add bank / card details to bid or buy
                                </Link>
                            )}
                        </div>
                    </Popup>
                    <Popup
                        open={howtoBuy}
                        size="md"
                        handleClose={() => setHowToBuy(false)}
                        cstmModalClass="rctplyPopup"
                    >
                        <ReactPlayer url={'https://www.youtube.com/embed/0wEyxMTnAfs'} volume />
                    </Popup>
                </div>
                {viewMoreIfo && (
                    <ProductMoreinfo
                        open={viewMoreIfo}
                        handleClose={() => setMoreInfo(false)}
                        data={viewProduct[key]}
                        itemArray={viewProduct[key].itemPropsArray[trackIndex]}
                    />
                )}
                <CustomDialog title="" open={toggleAutoDialog} function={changeAutoDialogStatus}>
                    <div className="bidCnfModalCnt">
                        <h4> Confirm Bid To Reserve Shares </h4>
                        <h5>
                            You have placed a bid to reserve shares for this offering. This is only
                            a reservation, allowing you to purchase shares when they go on sale
                            (subject to SEC qualification).
                            <br />
                            <br />
                        </h5>
                    </div>
                    <div className="bidCnfModalCnt">
                        <h4> Auto-Purchase Option </h4>
                        <h5>
                            Auto-purchase makes buying your SongShares as easy as possible. Checking
                            this box automatically purchases your reserved shares, using your
                            preferred payment method, when they go on sale. This can be changed in
                            your dashboard at any time.
                            <br />
                            {/* <br />
                                It can always turn this option off in your dashboard or just uncheck
                                the box below */}
                        </h5>
                        <div className="row">{Object.values(mapData(autoPayData))}</div>
                        <>
                            <h5>
                                Note: Since you have selected auto purchase we need to capture your
                                SSN number per SEC regulations.
                            </h5>
                        </>
                    </div>
                    <div className="actionWrapper">
                        <Button
                            onClick={() => {
                                setToggleAutoDialog(false)
                                formikAuto.handleReset()
                            }}
                        >
                            Cancel
                        </Button>
                        <PrimaryButton
                            onClick={formikAuto.handleSubmit}
                            type="button"
                            label="Submit"
                        />
                    </div>
                </CustomDialog>
                <CustomDialog
                    title="Confirm Bid To Reserve Shares"
                    open={increasePopup}
                    function={changeDialogStatus}
                >
                    <div className="bidCnfModalCnt">
                        <h5>
                            Current Bid has been increased from ${localStorage.old_price} to $
                            {localStorage.current_price}
                        </h5>
                        <h5>Please confirm if you want to continue with this action</h5>
                    </div>
                    <div className="actionWrapper">
                        <Button onClick={() => cancelFunc()}>Cancel</Button>
                        <PrimaryButton
                            onClick={() => handleIncreaseFunc()}
                            type="button"
                            label="Submit"
                        />
                    </div>
                </CustomDialog>
            </>
        </AppLayout>
    )
}

export default ProductView
